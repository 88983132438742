import { Box, Container } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import BasicDetails from 'components/number-porting/geographic-port/steps/BasicDetails'
import InstallDetails from 'components/number-porting/geographic-port/steps/InstallDetails'
import NumberDetails from 'components/number-porting/geographic-port/steps/NumberDetails'
import NumbersToPort from 'components/number-porting/geographic-port/steps/NumbersToPort'
import PortDates from 'components/number-porting/geographic-port/steps/PortDates'
import Summary from 'components/number-porting/geographic-port/steps/Summary'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import TrunkSelection from './steps/TrunkSelection'

const useStyles = makeStyles(theme => ({
  stepper: {
    background: 'transparent',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-2),
    },
  },
}))

function getSteps() {
  return [
    'Basic Details',
    'Number Details',
    'Customer Details',
    'Numbers to Port',
    'Port Date',
    'Trunk Selection',
    'Summary',
  ]
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <BasicDetails />
    case 1:
      return <NumberDetails />
    case 2:
      return <InstallDetails />
    case 3:
      return <NumbersToPort />
    case 4:
      return <PortDates />
    case 5:
      return <TrunkSelection />
    case 6:
      return <Summary />
    default:
      return 'Unknown step'
  }
}

function GeographicPortStepper(props) {
  const classes = useStyles()
  const { addToast } = useToasts()
  const { loading, setLoading } = useContext(LoadingContext)
  const {
    state,
    dispatch,
    validateBasicDetails,
    validateNumberDetails,
    validateInstallationDetails,
    validateNumbersToPort,
    validatePortingDate,
    validateTrunkSelection,
    submitOrder,
  } = useContext(GeographicPortContext)
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState(new Set())
  const steps = getSteps()

  async function stepIsValid() {
    switch (activeStep) {
      case 0:
        return validateBasicDetails()
      case 1:
        const numberDetailsValid = await validateNumberDetails()
        if (numberDetailsValid) {
          addToast(
            "The number port details have been saved as draft, feel free to come back later if needed, you won't lose the details you've entered so far.",
            { appearance: 'success' }
          )
        }
        return numberDetailsValid
      case 2:
        const installDetailsValid = await validateInstallationDetails()
        if (installDetailsValid) {
          addToast('Number port saved.', { appearance: 'success' })
        }
        return installDetailsValid
      case 3:
        const numbersToPortValid = await validateNumbersToPort()
        if (numbersToPortValid) {
          addToast('Number port saved.', { appearance: 'success' })
        }
        return numbersToPortValid
      case 4:
        const portDateValid = await validatePortingDate()
        if (portDateValid) {
          addToast('Number port saved.', { appearance: 'success' })
        }
        return portDateValid
      case 5:
        const trunkSelectionValid = await validateTrunkSelection()
        if (trunkSelectionValid) {
          addToast('Number port saved.', { appearance: 'success' })
        }
        return trunkSelectionValid
      case 6:
        return await submitOrder()
      default:
        return true
    }
  }

  const handleNext = async () => {
    setLoading(true)
    dispatch({ type: 'SET_ERRORS', payload: {} })
    const valid = await stepIsValid(activeStep)
    setLoading(false)

    if (valid) {
      const newCompleted = new Set(completed)
      newCompleted.add(activeStep)
      setCompleted(newCompleted)

      /**
       * Sigh... it would be much nicer to replace the following if conditional with
       * `if (!this.allStepsComplete())` however state is not set when we do this,
       * thus we have to resort to not being very DRY.
       */
      if (completed.size !== totalSteps()) {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed
              // find the first step that has been completed
              steps.findIndex((step, i) => !completed.has(i))
            : activeStep + 1

        setActiveStep(newActiveStep)
      }
    }
  }

  const handleBack = () => {
    dispatch({ type: 'SET_ERRORS', payload: {} })
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const totalSteps = () => {
    return steps.length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  function isStepComplete(step) {
    return completed.has(step)
  }

  const completedSteps = () => {
    return completed.size
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  return (
    <React.Fragment>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item lg={12} xl={8}>
          <Stepper
            id='stepper'
            className={classes.stepper}
            activeStep={activeStep}
            alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={() => setActiveStep(index)}
                  completed={isStepComplete(index)}>
                  <Hidden smDown>{label}</Hidden>
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <div className={classes.content}>
            {allStepsCompleted() ? (
              <Container maxWidth='md'>
                <Box mt={5} mb={5}>
                  <Box mb={2}>
                    <Typography variant='h6' gutterBottom>
                      Thank you for raising your port order!
                    </Typography>
                  </Box>

                  <Box mb={2}>
                    <Typography variant='body1' gutterBottom>
                      Number Port Reference Number: <strong>{state.id.value}</strong>
                    </Typography>
                  </Box>

                  <Box mb={2}>
                    <Typography variant='body1' gutterBottom>
                      You will receive email notifications when the port status changes.
                    </Typography>
                  </Box>

                  <MuiAlert severity='info'>
                    As a reminder please ensure that you raise an order for new DDIs on
                    the{' '}
                    <a
                      href='https://portal.ipecs-cloud.co.uk/back/cust/orderProcess/orderProcess.do'
                      target='_blank'
                      rel='noopener noreferrer'>
                      OMS Cloud Portal
                    </a>{' '}
                    for any numbers you are porting, so that we can add them to your
                    account ready for you to configure.
                  </MuiAlert>

                  <Box mt={5} display='flex'>
                    <Box mr={1}>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                          dispatch({ type: 'RESET' })
                          setActiveStep(0)
                          setCompleted(new Set())
                          dispatch({ type: 'SET_LOADING', payload: false })
                        }}>
                        Submit a New Order
                      </Button>
                    </Box>

                    <Box mr={1}>
                      <Button
                        color='primary'
                        variant='contained'
                        component={NavLink}
                        to='/number-porting'>
                        View My Number Ports
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        variant='contained'
                        component='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://portal.ipecs-cloud.co.uk/back/cust/orderProcess/orderProcess.do'>
                        Go To OMS To Add DDI To Customer's Account'?
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Container>
            ) : (
              <div>
                {isLastStep() && (
                  <MuiAlert severity='info'>
                    Please review the below details. If all details are correct you can
                    confirm the port request using the button at the bottom of this page.
                  </MuiAlert>
                )}

                {getStepContent(activeStep)}

                {state.main_error && (
                  <Box mt={3}>
                    <MuiAlert severity='error'>{state.main_error}</MuiAlert>
                  </Box>
                )}

                <Box mt={2} textAlign='right'>
                  <Typography variant='caption'>
                    * indicates that the field is mandatory
                  </Typography>
                </Box>

                {Object.values(state.errors).length > 0 && (
                  <Box mt={2}>
                    <MuiAlert severity='error'>
                      {Object.values(state.errors).map((error, index) => {
                        return <div key={`error-${index}`}>{error[0]}</div>
                      })}
                    </MuiAlert>
                  </Box>
                )}

                <Box mt={3} textAlign='right'>
                  {state.id.value && (
                    <Chip
                      style={{ marginRight: '1rem' }}
                      label={`Number Port Reference Number: ${state.id.value}`}
                    />
                  )}
                  <Button disabled={activeStep === 0 || loading} onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    id='geographic-port-next-button'
                    disabled={loading}
                    variant='contained'
                    color='primary'
                    onClick={handleNext}>
                    {isLastStep() ? 'Confirm & Submit Port Order' : 'Next'}
                  </Button>
                </Box>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default GeographicPortStepper
