import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import { IconButton } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Slide from '@material-ui/core/Slide'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {
  Assignment,
  Category,
  Chat,
  Cloud,
  Dialpad,
  FastForward,
  Home,
  LibraryBooks,
  LocalLibrary,
  LocalOffer,
  LocationOn,
  School,
  SettingsInputComponent,
  ShoppingBasket,
  ShoppingCart,
  Videocam,
} from '@material-ui/icons'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import BarChart from '@material-ui/icons/BarChart'
import CloseIcon from '@material-ui/icons/Close'
import DnsIcon from '@material-ui/icons/Dns'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import GroupIcon from '@material-ui/icons/Group'
import HomeIcon from '@material-ui/icons/Home'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import ListIcon from '@material-ui/icons/List'
import MoneyIcon from '@material-ui/icons/Money'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import PhoneIcon from '@material-ui/icons/Phone'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined'
import RecordVoiceIcon from '@material-ui/icons/RecordVoiceOver'
import RouterIcon from '@material-ui/icons/Router'
import SettingsIcon from '@material-ui/icons/Settings'
import TollIcon from '@material-ui/icons/Toll'
import WifiIcon from '@material-ui/icons/Wifi'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { MainNavContext } from 'contexts/MainNavContext'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
  divider: {
    backgroundColor: '#FFF2',
  },
  appBar: {
    backgroundColor: theme.palette.darkBlue,
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iframeHolder: {
    width: '100%',
    height: '100%',
    background: 'url(/BesoIcon.svg) center center no-repeat',
  },
  iframe: {
    height: '100%',
    width: '100%',
    frameBorder: 0,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function MenuItems() {
  const classes = useStyles()
  const { state, dispatch } = useContext(MainNavContext)
  const { user, hasPermission, hasAccountFeature } = useContext(AuthContext)
  const { hasFeature } = useContext(FeatureToggleContext)
  const [campaignsOpen, setCampaignsOpen] = React.useState(false)

  const handleSetupClick = () => {
    dispatch({ type: 'SET_SETUP_OPEN', payload: !state.setup_open })
  }

  const handleProductsClick = () => {
    dispatch({ type: 'SET_PRODUCTS_OPEN', payload: !state.products_open })
  }

  const handleServicesClick = () => {
    dispatch({ type: 'SET_SERVICES_OPEN', payload: !state.services_open })
  }

  const handleAppsClick = () => {
    if (state.apps_open !== true) {
      dispatch({ type: 'SET_APPS_OPEN', payload: true })
    }
  }

  const handleAppsToggle = () => {
    dispatch({ type: 'SET_APPS_OPEN', payload: false })
  }

  const handleNumberPortingClick = () => {
    dispatch({ type: 'SET_NUMBER_PORTING_OPEN', payload: !state.number_porting_open })
  }

  const handleOrdersClick = () => {
    dispatch({ type: 'SET_ORDERS_OPEN', payload: !state.orders_open })
  }

  const handleCampaignsOpen = () => {
    setCampaignsOpen(true)
  }

  const handleCampaignsClose = () => {
    setCampaignsOpen(false)
  }

  return (
    <div>
      {user.getting_started_percent < 100 && (
        <>
          <ListItem
            button
            key='Getting Started'
            component={NavLink}
            to='/getting-started'>
            <ListItemIcon>
              <CircularProgressWithLabel value={user.getting_started_percent} />
            </ListItemIcon>
            <ListItemText primary='Getting Started' />
          </ListItem>
          <Divider className={classes.divider} />
        </>
      )}

      <ListItem button key='Sales Hub' component={NavLink} to='/sales-hub'>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary='Sales Hub' />
      </ListItem>

      {hasAccountFeature('quote') && hasPermission('quote') && (
        <ListItem
          button
          id='quotes-menu-item'
          key='Quotes'
          component={NavLink}
          to='/quotes'>
          <ListItemIcon>
            <FormatListNumberedIcon />
          </ListItemIcon>
          <ListItemText primary='QuickQuote' />
        </ListItem>
      )}

      {user.role === 'admin' &&
        hasAccountFeature('quote') &&
        hasFeature('reseller_sales_dash') && (
          <ListItem
            button
            id='dashboard-menu-item'
            key='Dashboard'
            component={NavLink}
            to='/sales-dash'>
            <ListItemIcon>
              <BarChart />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
        )}

      {hasAccountFeature('library') && hasPermission('library') && (
        <React.Fragment>
          <ListItem
            button
            id='library-menu-item'
            key='Library'
            component={NavLink}
            to='/library'>
            <ListItemIcon>
              <LibraryBooks />
            </ListItemIcon>
            <ListItemText primary='Library' />
          </ListItem>

          <ListItem
            button
            id='my-library-menu-item'
            key='MyLibrary'
            component={NavLink}
            to='/my-library'>
            <ListItemIcon>
              <LocalLibrary />
            </ListItemIcon>
            <ListItemText primary='MyLibrary' />
          </ListItem>

          <ListItem
            button
            id='video-library-menu-item'
            key='Video Library'
            component={NavLink}
            to='/video-library'>
            <ListItemIcon>
              <Videocam />
            </ListItemIcon>
            <ListItemText primary='Video Library' />
          </ListItem>

          <ListItem
            button
            id='quickstart-library-menu-item'
            key='QuickStart'
            component={NavLink}
            to='/quickstart-library'>
            <ListItemIcon>
              <FastForward />
            </ListItemIcon>
            <ListItemText primary='QuickStart' />
          </ListItem>

          <ListItem
            button
            id='training-library-menu-item'
            key='Training'
            component={NavLink}
            to='/training-library'>
            <ListItemIcon>
              <School />
            </ListItemIcon>
            <ListItemText primary='Training' />
          </ListItem>

          <ListItem
            button
            id='marketing-library-menu-item'
            key='Marketing'
            component={NavLink}
            to='/marketing-library'>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary='Marketing' />
          </ListItem>
        </React.Fragment>
      )}

      {hasAccountFeature('campaigns') && (
        <ListItem button key='Social Campaigns' onClick={handleCampaignsOpen}>
          <ListItemIcon>
            <Chat />
          </ListItemIcon>
          <ListItemText primary='Social Campaigns' />
        </ListItem>
      )}

      {hasAccountFeature('price_list') && hasPermission('price_list') && (
        <ListItem button key='Price List' component={NavLink} to='/price-list'>
          <ListItemIcon>
            <LocalOffer />
          </ListItemIcon>
          <ListItemText primary='Price List' />
        </ListItem>
      )}

      <>
        <ListItem id='orders-menu-item' button onClick={handleOrdersClick}>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary='Orders' />
          {state.orders_open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={state.orders_open} timeout='auto' unmountOnExit>
          <ListItem
            button
            id='cloud-orders-info-menu-item'
            key='Cloud Orders Info'
            component={NavLink}
            to='/cloud-orders-info'>
            <ListItemIcon>
              <Cloud />
            </ListItemIcon>
            <ListItemText primary='Cloud Orders Info' />
          </ListItem>

          <ListItem
            button
            key='Order Online'
            component='a'
            target='_blank'
            href='https://orders.wearepragma.co.uk'>
            <ListItemIcon>
              <ShoppingBasket />
            </ListItemIcon>
            <ListItemText primary='Order Online' />
          </ListItem>
        </Collapse>
      </>

      {hasAccountFeature('number_porting') && hasPermission('number_porting') && (
        <>
          <ListItem
            id='number-porting-menu-item'
            button
            onClick={handleNumberPortingClick}>
            <ListItemIcon>
              <SettingsInputComponent />
            </ListItemIcon>
            <ListItemText primary='Number Porting' />
            {state.number_porting_open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={state.number_porting_open} timeout='auto' unmountOnExit>
            <ListItem
              button
              id='portability-check-menu-item'
              key='Portability Check'
              component={NavLink}
              to='/number-porting/portability-check'>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary='Portability Check' />
            </ListItem>

            <ListItem
              button
              id='geographic-port-menu-item'
              key='Create Port Order'
              component={NavLink}
              to='/number-porting/geographic-port'>
              <ListItemIcon>
                <LocationOn />
              </ListItemIcon>
              <ListItemText primary='Create Port Order' />
            </ListItem>

            <ListItem
              button
              id='number-ports-statuses-menu-item'
              key='Statuses'
              component={NavLink}
              to='/number-porting'>
              <ListItemIcon>
                <Category />
              </ListItemIcon>
              <ListItemText primary='Statuses' />
            </ListItem>
          </Collapse>
        </>
      )}

      {user.role === 'admin' && (
        <>
          <ListItem button onClick={handleSetupClick}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary='Setup' />
            {state.setup_open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={state.setup_open} timeout='auto' unmountOnExit>
            {user.role === 'admin' && (
              <ListItem
                button
                id='users-menu-item'
                key='Users'
                component={NavLink}
                to='/users'>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary='Users' />
              </ListItem>
            )}

            {hasAccountFeature('quote') && (
              <>
                <ListItem button id='products-menu-item' onClick={handleProductsClick}>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary='Products' />
                  {state.products_open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={state.products_open} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      component={NavLink}
                      to='/products/hardware'>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary='Devices' />
                    </ListItem>

                    <ListItem
                      button
                      component={NavLink}
                      className={classes.nested}
                      to='/products/uc-applications'
                      onClick={handleAppsClick}>
                      <ListItemIcon>
                        <PhoneIphoneIcon />
                      </ListItemIcon>
                      <ListItemText primary='Applications & Integrations' />
                      {state.apps_open ? (
                        <ExpandLess onClick={handleAppsToggle} />
                      ) : (
                        <ExpandMore onClick={handleAppsToggle} />
                      )}
                    </ListItem>

                    <Collapse in={state.apps_open} timeout='auto' unmountOnExit>
                      <List component='div' disablePadding>
                        <ListItem
                          button
                          className={classes.subNested}
                          component={NavLink}
                          to='/products/uc-licences'>
                          <ListItemIcon>
                            <TollIcon />
                          </ListItemIcon>
                          <ListItemText primary='UCP Licences' />
                        </ListItem>
                      </List>
                    </Collapse>

                    <ListItem
                      button
                      onClick={handleServicesClick}
                      className={classes.nested}>
                      <ListItemIcon>
                        <DnsIcon />
                      </ListItemIcon>
                      <ListItemText primary='Services' />
                      {state.services_open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={state.services_open} timeout='auto' unmountOnExit>
                      <List component='div' disablePadding>
                        <ListItem
                          button
                          className={classes.subNested}
                          component={NavLink}
                          to='/products/connectivity'>
                          <ListItemIcon>
                            <WifiIcon />
                          </ListItemIcon>
                          <ListItemText primary='Connectivity' />
                        </ListItem>
                        <ListItem
                          button
                          className={classes.subNested}
                          component={NavLink}
                          to='/products/connectivity-hardware'>
                          <ListItemIcon>
                            <RouterIcon />
                          </ListItemIcon>
                          <ListItemText primary='Connectivity Hardware' />
                        </ListItem>
                        <ListItem
                          button
                          className={classes.subNested}
                          component={NavLink}
                          to='/products/engineering'>
                          <ListItemIcon>
                            <AccessibilityIcon />
                          </ListItemIcon>
                          <ListItemText primary='Engineering' />
                        </ListItem>
                      </List>
                    </Collapse>

                    <ListItem
                      button
                      className={classes.nested}
                      component={NavLink}
                      to='/products/call-recording'>
                      <ListItemIcon>
                        <RecordVoiceIcon />
                      </ListItemIcon>
                      <ListItemText primary='Call Recording' />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      component={NavLink}
                      to='/products/bundled-minutes'>
                      <ListItemIcon>
                        <HourglassEmptyIcon />
                      </ListItemIcon>
                      <ListItemText primary='Bundled Minutes' />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      component={NavLink}
                      to='/products/number-porting'>
                      <ListItemIcon>
                        <Dialpad />
                      </ListItemIcon>
                      <ListItemText primary='Number Porting' />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      component={NavLink}
                      to='/products/system'>
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary='System' />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  id='tariffs-menu-item'
                  button
                  key='Tariffs'
                  component={NavLink}
                  to='/tariffs'>
                  <ListItemIcon>
                    <MoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary='Tariffs' />
                </ListItem>
                <ListItem
                  button
                  id='discount-codes-menu-item'
                  key='Discount Codes'
                  component={NavLink}
                  to='/discount-codes'>
                  <ListItemIcon>
                    <MoneyOffIcon />
                  </ListItemIcon>
                  <ListItemText primary='Discount Codes' />
                </ListItem>
                <ListItem
                  button
                  id='leasing-menu-item'
                  key='Leasing'
                  component={NavLink}
                  to='/leasing'>
                  <ListItemIcon>
                    <ReceiptOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Leasing' />
                </ListItem>
                <ListItem
                  button
                  id='proposal-menu-item'
                  key='Proposal'
                  component={NavLink}
                  to='/proposal'>
                  <ListItemIcon>
                    <ReceiptOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Proposal' />
                </ListItem>
              </>
            )}
          </Collapse>
        </>
      )}

      {hasFeature('candio_promotion') && (
        <React.Fragment>
          <Divider className={classes.divider} />

          <ListItem button key='Candio' component={NavLink} to='/candio'>
            <ListItemIcon>
              <img
                height='22px'
                src='https://candio.co.uk/wp-content/uploads/cropped-Favicon-2-Candio-32x32.png'
                alt='Candio'
              />
            </ListItemIcon>
            <CandioListItemText primary='Candio' />
          </ListItem>
        </React.Fragment>
      )}

      <Dialog
        fullScreen
        open={campaignsOpen}
        onClose={handleCampaignsClose}
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleCampaignsClose}
              aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Social Campaigns
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.iframeHolder}>
          <iframe
            className={classes.iframe}
            title='beso-social-campaigns'
            src='https://app.beso.social'
          />
        </div>
      </Dialog>
    </div>
  )
}
const CandioListItemText = withStyles(theme => ({
  primary: {
    fontFamily: '"Montserrat", sans-serif',
  },
}))(ListItemText)

function CircularProgressWithLabel(props) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress variant='determinate' {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'>
        <Typography
          variant='caption'
          component='div'
          color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default MenuItems
