import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { create, get, update } from 'api/products/network-services'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(3),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { loading, setLoading } = useContext(LoadingContext)
  const [hideContent, setHideContent] = useState(true)
  const [values, setValues] = useState({
    name: { value: '', error: '' },
    product_code: { value: '', error: '' },
    description: { value: '', error: '' },
    upfront_cost_price: { value: '', error: '' },
    monthly_cost_price: { value: '', error: '' },
    upfront_rrp: { value: '', error: '' },
    monthly_rrp: { value: '', error: '' },
  })
  const [errors, setErrors] = useState([])
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const product = await get(id)
      const dataValues = {
        name: { value: product.data.name },
        product_code: { value: product.data.product_code },
        description: { value: product.data.description },
        upfront_cost_price: { value: getDisplayPrice(product.data.upfront_cost_price) },
        monthly_cost_price: { value: getDisplayPrice(product.data.monthly_cost_price) },
        upfront_rrp: { value: getDisplayPrice(product.data.upfront_rrp) },
        monthly_rrp: { value: getDisplayPrice(product.data.monthly_rrp) },
      }
      setValues(dataValues)
      setLoading(false)
      setHideContent(false)
    }

    if (id !== undefined) {
      fetchData()
    } else {
      setLoading(false)
      setHideContent(false)
    }
  }, [id, setValues, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      name: values.name.value,
      product_code: values.product_code.value,
      description: values.description.value,
      upfront_cost_price: values.upfront_cost_price.value * 100,
      monthly_cost_price: values.monthly_cost_price.value * 100,
      upfront_rrp: values.upfront_rrp.value * 100,
      monthly_rrp: values.monthly_rrp.value * 100,
    }

    const result = id ? await update(id, body) : await create(body)
    if (result.success) {
      queryClient.removeQueries('network-services-products')
      setLoading(false)
      props.history.push({
        pathname: '/products/engineering',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const getDisplayPrice = price => {
    if (price === 0) {
      return price.toFixed(2)
    } else if (price > 0) {
      return (price / 100).toFixed(2)
    }

    return ''
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        {id ? 'Update Engineering' : 'Create Engineering Product'}
      </Typography>
      <Grid item md={5}>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Paper className={classes.paper}>
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Name'
              name='name'
              defaultValue={values.name.value}
              autoFocus
              onChange={handleChange.bind(this)}
            />
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              id='product_code'
              label='Product Code'
              name='product_code'
              defaultValue={values.product_code.value}
              onChange={handleChange.bind(this)}
            />
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              id='description'
              label='Description'
              name='description'
              defaultValue={values.description.value}
              onChange={handleChange.bind(this)}
              multiline
              rows={4}
            />
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              type='number'
              step='0.01'
              InputLabelProps={{
                shrink: true,
              }}
              id='upfront_cost_price'
              label={t('Upfront Cost')}
              name='upfront_cost_price'
              defaultValue={values.upfront_cost_price.value}
              onChange={handleChange.bind(this)}
            />
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              type='number'
              step='0.01'
              id='monthly_cost_price'
              label={t('Monthly Cost')}
              name='monthly_cost_price'
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={values.monthly_cost_price.value}
              onChange={handleChange.bind(this)}
            />
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              type='number'
              step='0.01'
              id='upfront_rrp'
              label={t('Upfront RRP')}
              name='upfront_rrp'
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={values.upfront_rrp.value}
              onChange={handleChange.bind(this)}
            />
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              type='number'
              step='0.01'
              id='monthly_rrp'
              label={t('Monthly RRP')}
              name='monthly_rrp'
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={values.monthly_rrp.value}
              onChange={handleChange.bind(this)}
            />
          </Paper>

          {errors.length > 0 && (
            <MuiAlert severity='error'>
              {errors.map(error => {
                return <div>{error[1]}</div>
              })}
            </MuiAlert>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            {id ? 'Update' : 'Create'}
          </Button>
        </form>
      </Grid>
    </div>
  )
}

export default Form
