import { useMutation, useQueryClient } from '@tanstack/react-query'
import { LoadingContext } from 'contexts/LoadingContext'
import { useContext, useState } from 'react'

function useProductCategories({
  queryKey,
  createFn,
  updateFn,
  deleteFn,
  moveUpFn,
  moveDownFn,
}) {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const queryClient = useQueryClient()
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { setLoading } = useContext(LoadingContext)

  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [adding, setAdding] = useState(false)

  const createMutation = useMutation(data => createFn(data.data), {
    onMutate: () => {
      setAdding(true)
      setLoading(true)
    },
    onSettled: () => {
      setAdding(false)
      setLoading(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully created category')
      setSnackbarOpen(true)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to create category')
      setSnackbarOpen(true)
    },
  })

  const updateMutation = useMutation(data => updateFn(data.id, data.data), {
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
      setEditing(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully updated category')
      setSnackbarOpen(true)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to update category')
      setSnackbarOpen(true)
    },
  })

  const deleteMutation = useMutation(data => deleteFn(data.id), {
    onMutate: () => {
      setLoading(true)
      setDeleting(true)
    },
    onSettled: () => {
      setLoading(false)
      setDeleting(false)
      setDeleted(true)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted category')
      setSnackbarOpen(true)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete category')
      setSnackbarOpen(true)
    },
  })

  const moveUpMutation = useMutation(data => moveUpFn(data.id), {
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to move category')
      setSnackbarOpen(true)
    },
  })

  const moveDownMutation = useMutation(data => moveDownFn(data.id), {
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to move category')
      setSnackbarOpen(true)
    },
  })

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return {
    page,
    perPage,
    setPage,
    setPerPage,
    createMutation,
    updateMutation,
    deleteMutation,
    moveUpMutation,
    moveDownMutation,
    snackbarSeverity,
    snackbarMessage,
    snackbarOpen,
    handleSnackbarClose,
    setSnackbarSeverity,
    setSnackbarMessage,
    setSnackbarOpen,
    adding,
    editing,
    setEditing,
    deleting,
    deleted,
  }
}

export default useProductCategories
