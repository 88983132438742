import config from 'config'
import { getJsonHeaders } from './helpers'

export async function options(id = undefined) {
  try {
    let url = config.API_URL + `/api/quotes/builder/options`
    if (id) {
      url += `?quote=${id}`
    }

    const result = await fetch(url)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function details(id) {
  try {
    const result = await fetch(config.API_URL + `/api/quotes/builder/` + id)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function manualConfigData(id) {
  try {
    const result = await fetch(config.API_URL + `/api/quotes/manual-builder/` + id)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function saveManualConfigData(id, body) {
  try {
    let url = `/api/quotes/manual-builder/${id}`

    const res = await fetch(config.API_URL + url, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    return res.status === 201 || res.status === 200
  } catch (e) {
    return false
  }
}

export async function customerDetails(id, body) {
  try {
    let url = `/api/quotes/builder/customer-details`
    if (id) {
      url = url + `/${id}`
    }
    const res = await fetch(config.API_URL + url, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 201 || res.status === 200) {
      const decoded = await res.json()
      return decoded.data.id
    }

    return false
  } catch (e) {
    return false
  }
}

export async function services(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/services/` + id, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      const decoded = await res.json()
      return decoded.data.id
    }

    return false
  } catch (e) {
    return false
  }
}

export async function hardware(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/hardware/` + id, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      const decoded = await res.json()
      return decoded.data.id
    }

    return false
  } catch (e) {
    return false
  }
}

export async function network(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/network/` + id, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      const decoded = await res.json()
      return decoded.data.id
    }

    return false
  } catch (e) {
    return false
  }
}

export async function numbers(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/numbers/` + id, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      const decoded = await res.json()
      return decoded.data.id
    }

    return false
  } catch (e) {
    return false
  }
}

export async function expenditure(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/expenditure/` + id, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      const decoded = await res.json()
      return decoded.data.id
    }

    return false
  } catch (e) {
    return false
  }
}

export async function payment(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/payment/` + id, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      const decoded = await res.json()
      return decoded.data.id
    }

    return false
  } catch (e) {
    return false
  }
}

export async function summary(id) {
  try {
    const result = await fetch(config.API_URL + `/api/quotes/builder/summary/` + id)
    return await result.json()
  } catch (e) {
    return false
  }
}

export async function itemAdjust(id, body) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/item/` + id, {
      method: 'POST',
      headers: await getJsonHeaders(),
      body: JSON.stringify(body),
    })

    if (res.status === 200) {
      return await res.json()
    }

    return false
  } catch (e) {
    return false
  }
}

export async function itemDelete(id) {
  try {
    const res = await fetch(config.API_URL + `/api/quotes/builder/item/` + id, {
      method: 'DELETE',
      headers: await getJsonHeaders(),
    })

    if (res.status === 200) {
      return await res.json()
    }

    return false
  } catch (e) {
    return false
  }
}

export async function addMiscItem(id, body) {
  const res = await fetch(config.API_URL + `/api/quotes/builder/add-misc-item/` + id, {
    method: 'POST',
    headers: await getJsonHeaders(),
    body: JSON.stringify(body),
  })

  return {
    success: res.status === 200,
    body: await res.json(),
  }
}
