import { useContext, useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { LoadingContext } from 'contexts/LoadingContext'

function useProductsList({
  queryKey,
  listFn,
  deleteFn,
  restoreFn,
  favouriteFn,
  moveUpFn,
  moveDownFn,
}) {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const queryClient = useQueryClient()
  const [snackbarSeverity, setSnackbarSeverity] = useState('info')
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { setLoading } = useContext(LoadingContext)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIsOwner, setDeleteIsOwner] = useState(true)

  const query = useQuery({
    queryKey: [queryKey, page, perPage],
    queryFn: () => listFn(perPage, page),
    keepPreviousData: true,
  })

  useEffect(() => {
    setLoading(query.isFetching)
  }, [query.isFetching, setLoading])

  const deleteMutation = useMutation(data => deleteFn(data.id), {
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setDeleteOpen(false)
      setDeleteId(null)
      setLoading(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully deleted product')
      setSnackbarOpen(true)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to delete product')
      setSnackbarOpen(true)
    },
  })

  const restoreMutation = useMutation(data => restoreFn(data.id), {
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
      setSnackbarSeverity('success')
      setSnackbarMessage('Successfully restored product')
      setSnackbarOpen(true)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to restore product')
      setSnackbarOpen(true)
    },
  })

  const moveUpMutation = useMutation(data => moveUpFn(data.id), {
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to move product')
      setSnackbarOpen(true)
    },
  })

  const moveDownMutation = useMutation(data => moveDownFn(data.id), {
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
    },
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
    },
    onError: () => {
      setSnackbarSeverity('error')
      setSnackbarMessage('Failed to move product')
      setSnackbarOpen(true)
    },
  })

  const favouriteMutation = useMutation(data => favouriteFn(data.id, data.value), {
    onSuccess: () => {
      queryClient.removeQueries(queryKey)
    },
    onMutate: () => {
      setLoading(true)
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return {
    page,
    perPage,
    setPage,
    setPerPage,
    query,
    favouriteMutation,
    deleteMutation,
    restoreMutation,
    moveUpMutation,
    moveDownMutation,
    snackbarSeverity,
    snackbarMessage,
    snackbarOpen,
    handleSnackbarClose,
    setSnackbarSeverity,
    setSnackbarMessage,
    setSnackbarOpen,
    deleteOpen,
    deleteId,
    deleteIsOwner,
    setDeleteOpen,
    setDeleteId,
    setDeleteIsOwner,
  }
}

export default useProductsList
