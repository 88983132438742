import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { summary as fetchSummary } from 'api/quote-builder'
import QuoteSummary from 'components/quotes/QuoteSummary'
import Sharing from 'components/quotes/Sharing'
import { LoadingContext } from 'contexts/LoadingContext'
import { QuoteContext } from 'contexts/QuoteContext'
import { ViewContext } from 'contexts/ViewContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  loader: {
    marginTop: theme.spacing(12),
    textAlign: 'center',
  },
  container: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 220px',
    },
  },
  actionButton: {
    width: '100%',
    float: 'right',
    color: '#FFF',
    marginBottom: '6px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

function SummaryStep({ handleNext }) {
  const classes = useStyles()
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const { state, dispatch, hasLoaded } = useContext(QuoteContext)
  const { selectedView, setViewMenuItems, setSelectedView } = useContext(ViewContext)
  const [sharingOpen, setSharingOpen] = useState(false)

  const handleClickSharingOpen = () => {
    setSharingOpen(true)
  }

  const handleCloseSharing = () => {
    setSharingOpen(false)
  }

  useEffect(() => {
    setLoading(true)
    dispatch({ type: 'SET_SUMMARY', payload: null })

    fetchSummary(id).then(res => {
      dispatch({ type: 'SET_SUMMARY', payload: res })
      setLoading(false)
    })

    if (state.rawData.status !== 'won' && state.rawData.status !== 'lost') {
      setViewMenuItems(['External', 'Internal'])
      setSelectedView(state.settings.default_display)
    }

    return () => {
      setViewMenuItems([])
    }
  }, [
    id,
    state.rawData.status,
    state.stepsComleted,
    state.settings.default_display,
    dispatch,
    setLoading,
    setViewMenuItems,
    setSelectedView,
  ])

  if (!hasLoaded) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  if (!state.stepsCompleted) {
    return (
      <div className={classes.loader}>
        You have not completed and saved all steps of the quote builder.
      </div>
    )
  }

  if (!state.summary || loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div>
          <QuoteSummary
            selectedView={selectedView}
            priceBy={state.payment.price_by.value}
            summaryData={state.summary}
            leaseBoxes={state.settings.default_display}
          />
        </div>
        <div>
          <Button
            component={Link}
            href={`/api/quotes/builder/pdf/${id}`}
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.actionButton}>
            Download PDF
          </Button>

          <Button
            component={Link}
            href={`/api/quotes/builder/costing-sheet/${id}`}
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.actionButton}>
            Costing Sheet
          </Button>

          {state.options.pdf_generated && (
            <Button
              variant='contained'
              color='primary'
              onClick={handleClickSharingOpen}
              disabled={loading}
              className={classes.actionButton}>
              Sharing
            </Button>
          )}

          {handleNext && (
            <Button
              component={Link}
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleNext}
              className={classes.actionButton}>
              Generate Proposal
            </Button>
          )}
        </div>
      </div>

      <Dialog open={sharingOpen} keepMounted onClose={handleCloseSharing}>
        <DialogTitle>Quote Sharing</DialogTitle>
        <DialogContent>
          <Sharing id={id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSharing} color='primary'>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default SummaryStep
