import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { links as fetchLinks } from 'api/sales-hub'
import CandioBanner from 'components/CandioBanner'
import { AuthContext } from 'contexts/AuthContext'
import { FeatureToggleContext } from 'contexts/FeatureToggleContext'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Demo from './Demo'
import Help from './Help'
import News from './News'
import Training from './Training'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.values.lg,
    margin: 'auto',
  },
  items: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  item: {
    backgroundColor: theme.palette.darkBlue,
    color: '#FFF',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      height: '250px',
      padding: theme.spacing(3),
    },
  },
  quotes: {
    backgroundColor: theme.palette.darkBlue,
    color: '#FFF',
    textAlign: 'center',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  quoteButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}))

function SalesHub() {
  const classes = useStyles()
  const { hasFeature } = useContext(FeatureToggleContext)
  const { user, hasAccountFeature, hasPermission } = useContext(AuthContext)
  const { setLoading } = useContext(LoadingContext)
  const [links, setLinks] = useState({})
  const [hideContent, setHideContent] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setLinks(await fetchLinks())
      setHideContent(false)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  if (hideContent) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Sales Hub
      </Typography>

      {hasAccountFeature('quote') && hasPermission('quote') && (
        <div className={classes.quotes}>
          <Button
            component={Link}
            to={'/quotes'}
            variant='contained'
            color='primary'
            className={classes.quoteButton}>
            View My Quotes
          </Button>

          <Button
            component={Link}
            to={'/quotes/create'}
            variant='contained'
            color='primary'
            className={classes.quoteButton}>
            Create New Quote
          </Button>
        </div>
      )}

      <div className={classes.items}>
        {(user.type === 'distributor' ||
          (hasAccountFeature('quote') && hasPermission('quote'))) && (
          <div className={classes.item}>
            <Typography align='center' variant='h6'>
              <strong>Demo</strong>
            </Typography>

            <Demo link={links.demo} />
          </div>
        )}

        <div className={classes.item}>
          <Typography align='center' variant='h6'>
            <strong>Upcoming Training</strong>
          </Typography>

          <Training link={links.training} />
        </div>

        {(user.type === 'distributor' ||
          (hasAccountFeature('quote') && hasPermission('quote'))) && (
          <div className={classes.item}>
            <Typography align='center' variant='h6'>
              <strong>Help?</strong>
            </Typography>

            <Help />
          </div>
        )}

        <div className={classes.item}>
          <Typography align='center' variant='h6'>
            <strong>iPECS News</strong>
          </Typography>

          <News />
        </div>
      </div>

      {hasFeature('candio_promotion') && (
        <Box mt={3}>
          <CandioBanner />
        </Box>
      )}
    </div>
  )
}

export default SalesHub
