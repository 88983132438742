import { LoadingContext } from 'contexts/LoadingContext'
import { useContext } from 'react'

function Status() {
  const { setLoading } = useContext(LoadingContext)

  setLoading(false)

  return (
    <iframe
      src='https://ipecscloud.statuspage.io/'
      scrolling='no'
      style={{ border: 'none' }}
      width='100%'
      height='3400px'
    />
  )
}

export default Status
