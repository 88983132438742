import MuiAccordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import React from 'react'
import Title from './Title'

const useStyles = makeStyles(theme => ({
  summary: {
    backgroundColor: theme.palette.darkBlue,
    color: '#fff',
    marginBottom: '1px',
  },
  icon: {
    color: '#fff',
  },
  details: {
    backgroundColor: theme.palette.lightBlue,
    display: 'block',
  },
}))

function Accordion({ title, id, expanded, onChange, children }) {
  const classes = useStyles()

  return (
    <MuiAccordion elevation={0} expanded={expanded} onChange={onChange}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        id={id}>
        <Title variant='h5' margin={false}>
          {title}
        </Title>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>{children}</AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Accordion
