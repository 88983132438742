import { Box, Grid, IconButton, ListItemText, Tooltip } from '@material-ui/core'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Info } from '@material-ui/icons'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  valueCell: {
    width: '50%',
  },
}))

function Summary() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6}>
        <ContactDetails />
        <CustomerInstallationDetails />
        <CommunicationProvider />
        <PortDate />
      </Grid>

      <Grid item xs={12} xl={6}>
        <Paper className={classes.paper}>
          <Typography variant='h5' gutterBottom>
            Trunk Selection
          </Typography>

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Trunk</TableCell>
                  <TableCell className={classes.valueCell}>
                    {state.trunk_selection.value.replace('_', ' ').toUpperCase()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <MainNumberDetails />
        <Numbers
          title={
            <>
              Associated Numbers{' '}
              <Tooltip
                title={
                  <>
                    These are individual numbers that generally have the same area code
                    and are on the same account as the Main Billing Number.
                  </>
                }
                placement='right'>
                <IconButton color='default' size='small'>
                  <Info />
                </IconButton>
              </Tooltip>
            </>
          }
          items={state.number_details.associated_numbers}
        />
        <NumberRanges
          title={
            <>
              DDI Ranges{' '}
              <Tooltip
                title={
                  <>
                    DDI ranges are number ranges under the same account as the Main
                    Billing Number.
                  </>
                }
                placement='right'>
                <IconButton color='default' size='small'>
                  <Info />
                </IconButton>
              </Tooltip>
            </>
          }
          items={state.number_details.ddi_ranges}
        />
        <Numbers
          title={
            <>
              Single Number DDIs{' '}
              <Tooltip
                title={
                  <>
                    These are a unique single number for use with ISDN2 or ISDN30 circuits
                    and are commonly used to enable devices such as fax machines.
                  </>
                }
                placement='right'>
                <IconButton color='default' size='small'>
                  <Info />
                </IconButton>
              </Tooltip>
            </>
          }
          items={state.number_details.single_number_ddis}
        />
        <Numbers title='Other Numbers' items={state.number_details.other_numbers} />

        {state.status.value !== 'DRAFT' && (
          <Paper className={classes.paper}>
            <Typography variant='h5' gutterBottom>
              Notes
            </Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {state.notes.map((note, index) => (
                <ListItemText
                  key={`note-${index}`}
                  className={classes.message}
                  secondary={
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: note.content.replaceAll('\n', '<br />'),
                        }}
                      />
                      <br />
                      <br />
                      <span className={classes.messageDate}>
                        {note.created_at.datetime}
                      </span>
                    </>
                  }
                  primary={note.user.name + ' - ' + note.created_at.readable}
                />
              ))}
            </List>
          </Paper>
        )}
      </Grid>
    </Grid>
  )
}

function CustomerInstallationDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  const getAddress = () => {
    return [
      state.address_details.premises.value,
      state.address_details.street.value,
      state.address_details.town.value,
      state.address_details.county.value,
    ].filter(function (x) {
      return x !== '' && x !== null
    })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Customer Installation Details
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell className={classes.valueCell}>
                {state.customer.company_name.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell className={classes.valueCell}>
                {state.customer.first_name.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell className={classes.valueCell}>
                {state.customer.last_name.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Number</TableCell>
              <TableCell className={classes.valueCell}>
                {state.customer.contact_number.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell className={classes.valueCell}>
                {state.customer.email.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Job Title</TableCell>
              <TableCell className={classes.valueCell}>
                {state.customer.job_title.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Changing Address?</TableCell>
              <TableCell className={classes.valueCell}>
                {state.address_details.is_moving.value ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>

            {state.address_details.is_moving.value && (
              <TableRow>
                <TableCell>Previous Post Code</TableCell>
                <TableCell className={classes.valueCell}>
                  {state.address_details.old_post_code.value}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>Postcode</TableCell>
              <TableCell className={classes.valueCell}>
                {state.address_details.post_code.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell className={classes.valueCell}>
                {getAddress().map((x, index) => (
                  <div key={`address-${index}`}>{x}</div>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function ContactDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Contact Details
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Request Created By</TableCell>
              <TableCell className={classes.valueCell}>{state.created_by.name}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell className={classes.valueCell}>
                {state.contact.name.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Email</TableCell>
              <TableCell className={classes.valueCell}>
                {state.contact.email.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Phone Number</TableCell>
              <TableCell className={classes.valueCell}>
                {state.contact.phone_number.value}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function MainNumberDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Main Number Details
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Number</TableCell>
              <TableCell className={classes.valueCell}>
                {state.number_details.std_code.value}
                {state.number_details.main_number.local_number.value}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                Line Type{' '}
                <Tooltip
                  title={
                    <>
                      <Box marginBottom={1}>
                        A "Single line" port is a number that terminates on a single
                        socket and has no other associated numbers, whereas a "Multi line"
                        port terminates on equipment like a PBX, though it can be a single
                        telephone number that goes to multiple lines.
                      </Box>
                      <Box>
                        Some BT products that are attached to telephone lines
                        automatically make them a "Multi line".
                      </Box>
                    </>
                  }
                  placement='right'>
                  <IconButton color='default' size='small'>
                    <Info />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.valueCell}>
                {state.number_details.line_type.value.toUpperCase()}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Products Associated?</TableCell>
              <TableCell className={classes.valueCell}>
                {state.number_details.has_associated_products.value ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Has Remote Call Forwarding?</TableCell>
              <TableCell className={classes.valueCell}>
                {state.number_details.has_rcf.value ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>

            {state.number_details.has_rcf.value && (
              <TableRow>
                <TableCell>Line Has Remote Call Forwarding?</TableCell>
                <TableCell className={classes.valueCell}>
                  {state.number_details.rcf_post_code.value ? 'Yes' : 'No'}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>Featurenet Order?</TableCell>
              <TableCell className={classes.valueCell}>
                {state.number_details.is_featurenet_port.value ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function Numbers({ title, items }) {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        {title}
      </Typography>

      {items.length === 0 ? (
        <p>None</p>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              {items.map(x => (
                <TableRow key={`number-${x.local_number.value}`}>
                  <TableCell>
                    {x.std_code.value}
                    {x.local_number.value}
                  </TableCell>
                  <TableCell className={classes.valueCell}>
                    {x.action.value.toUpperCase()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  )
}

function NumberRanges({ title, items }) {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        {title}
      </Typography>

      {items.length === 0 ? (
        <p>None</p>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              {items.map(x => (
                <TableRow key={`number-${x.local_number_start.value}`}>
                  <TableCell>
                    {x.std_code.value}
                    {x.local_number_start.value} - {x.std_code.value}
                    {x.local_number_end.value}
                  </TableCell>
                  <TableCell className={classes.valueCell}>
                    {x.action.value.toUpperCase()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  )
}

function CommunicationProvider() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Communication Provider
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                Porting Numbers From{' '}
                <Tooltip
                  title={
                    <>
                      The Losing Communications Provider is who we are porting over from.
                      They are the communcation provider where the number is to be ported
                      from.
                    </>
                  }
                  placement='right'>
                  <IconButton color='default' size='small'>
                    <Info />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.valueCell}>
                {state.lcp.value}
                {state.lcp_other.value ? ` / ${state.lcp_other.value}` : ''}
              </TableCell>
            </TableRow>
            {state.lcp_account_ref.value && (
              <TableRow>
                <TableCell>Account Number</TableCell>
                <TableCell className={classes.valueCell}>
                  {state.lcp_account_ref.value}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function PortDate() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Port Date
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Earliest Port Date</TableCell>
              <TableCell className={classes.valueCell}>
                {state.port_date.value ? state.port_date.value : 'TBC'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell className={classes.valueCell}>
                {state.port_date_type.value.replace(/_/g, ' ')}
              </TableCell>
            </TableRow>
            {state.port_date_time.value && (
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell className={classes.valueCell}>
                  {state.port_date_time.value.replace('SLOT_', '')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default Summary
