import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MuiAlert from '@material-ui/lab/Alert'
import { get, update } from 'api/products/system'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FormDefaultPricing from './FormDefaultPricing'
import FormPricingByResellerType from './FormPricingByResellerType'
import { useQueryClient } from '@tanstack/react-query'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  orm: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

function Form(props) {
  const { id } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const queryClient = useQueryClient()
  const [hideContent, setHideContent] = useState(true)
  const [values, setValues] = useState()
  const [errors, setErrors] = useState([])
  const [isOwner, setIsOwner] = useState(true)
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const product = await get(id)
      const baseValues = {
        name: { value: product.data.name },
        product_code: {
          value: product.data.has_reseller_type_pricing ? '' : product.data.product_code,
        },
        description: { value: product.data.description },
        upfront_cost_price: { value: getDisplayPrice(product.data.upfront_cost_price) },
        monthly_cost_price: { value: getDisplayPrice(product.data.monthly_cost_price) },
        upfront_rrp: { value: getDisplayPrice(product.data.upfront_rrp) },
        monthly_rrp: { value: getDisplayPrice(product.data.monthly_rrp) },
        bill_upfront_monthly: { value: product.data.bill_upfront_monthly ? '1' : '0' },
        has_reseller_type_pricing: product.data.has_reseller_type_pricing,
        has_contract_pricing: product.data.has_contract_pricing,
      }

      const contractPricing = product.data.has_contract_pricing
        ? {
            upfront_cost_price_contract_3_year: {
              value: getDisplayPrice(product.data.upfront_cost_price_contract_3_year),
            },
            monthly_cost_price_contract_3_year: {
              value: getDisplayPrice(product.data.monthly_cost_price_contract_3_year),
            },
            upfront_rrp_contract_3_year: {
              value: getDisplayPrice(product.data.upfront_rrp_contract_3_year),
            },
            monthly_rrp_contract_3_year: {
              value: getDisplayPrice(product.data.monthly_rrp_contract_3_year),
            },
            upfront_cost_price_contract_5_year: {
              value: getDisplayPrice(product.data.upfront_cost_price_contract_5_year),
            },
            monthly_cost_price_contract_5_year: {
              value: getDisplayPrice(product.data.monthly_cost_price_contract_5_year),
            },
            upfront_rrp_contract_5_year: {
              value: getDisplayPrice(product.data.upfront_rrp_contract_5_year),
            },
            monthly_rrp_contract_5_year: {
              value: getDisplayPrice(product.data.monthly_rrp_contract_5_year),
            },
          }
        : {}

      const additionalPricing = product.data.has_reseller_type_pricing
        ? Object.assign(
            ...['wholesale', 'vsp', 'sp'].map(x => {
              const baseValues = {
                [`${x}_product_code`]: {
                  value: product.data.additional_pricing[x].product_code,
                },
                [`${x}_upfront_cost_price`]: {
                  value: getDisplayPrice(
                    product.data.additional_pricing[x].upfront_cost_price
                  ),
                },
                [`${x}_monthly_cost_price`]: {
                  value: getDisplayPrice(
                    product.data.additional_pricing[x].monthly_cost_price
                  ),
                },
                [`${x}_upfront_rrp`]: {
                  value: getDisplayPrice(product.data.additional_pricing[x].upfront_rrp),
                },
                [`${x}_monthly_rrp`]: {
                  value: getDisplayPrice(product.data.additional_pricing[x].monthly_rrp),
                },
              }

              const contractPricing = product.data.has_contract_pricing
                ? {
                    [`${x}_upfront_cost_price_contract_3_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x]
                          .upfront_cost_price_contract_3_year
                      ),
                    },
                    [`${x}_monthly_cost_price_contract_3_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x]
                          .monthly_cost_price_contract_3_year
                      ),
                    },
                    [`${x}_upfront_rrp_contract_3_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x].upfront_rrp_contract_3_year
                      ),
                    },
                    [`${x}_monthly_rrp_contract_3_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x].monthly_rrp_contract_3_year
                      ),
                    },
                    [`${x}_upfront_cost_price_contract_5_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x]
                          .upfront_cost_price_contract_5_year
                      ),
                    },
                    [`${x}_monthly_cost_price_contract_5_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x]
                          .monthly_cost_price_contract_5_year
                      ),
                    },
                    [`${x}_upfront_rrp_contract_5_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x].upfront_rrp_contract_5_year
                      ),
                    },
                    [`${x}_monthly_rrp_contract_5_year`]: {
                      value: getDisplayPrice(
                        product.data.additional_pricing[x].monthly_rrp_contract_5_year
                      ),
                    },
                  }
                : {}

              return Object.assign(baseValues, contractPricing)
            })
          )
        : {}

      setValues({ ...baseValues, ...additionalPricing, ...contractPricing })
      setIsOwner(product.data.is_owner)
      setLoading(false)
      setHideContent(false)
    }

    fetchData()
  }, [id, setValues, setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const body = {
      name: values.name.value,
      product_code: values.product_code.value,
      description: values.description.value,
      upfront_cost_price: values.upfront_cost_price.value * 100,
      monthly_cost_price: values.monthly_cost_price.value * 100,
      upfront_rrp: values.upfront_rrp.value * 100,
      monthly_rrp: values.monthly_rrp.value * 100,
      bill_upfront_monthly: values.bill_upfront_monthly.value,
    }

    if (values.has_contract_pricing) {
      body.upfront_cost_price_contract_3_year =
        values.upfront_cost_price_contract_3_year.value * 100
      body.monthly_cost_price_contract_3_year =
        values.monthly_cost_price_contract_3_year.value * 100
      body.upfront_rrp_contract_3_year = values.upfront_rrp_contract_3_year.value * 100
      body.monthly_rrp_contract_3_year = values.monthly_rrp_contract_3_year.value * 100
      body.upfront_cost_price_contract_5_year =
        values.upfront_cost_price_contract_5_year.value * 100
      body.monthly_cost_price_contract_5_year =
        values.monthly_cost_price_contract_5_year.value * 100
      body.upfront_rrp_contract_5_year = values.upfront_rrp_contract_5_year.value * 100
      body.monthly_rrp_contract_5_year = values.monthly_rrp_contract_5_year.value * 100
    }

    if (values.has_reseller_type_pricing) {
      const resellerTypePricing = ['wholesale', 'vsp', 'sp'].map(x => {
        const basePricing = {
          product_code: values[`${x}_product_code`].value,
          upfront_cost_price: values[`${x}_upfront_cost_price`].value * 100,
          monthly_cost_price: values[`${x}_monthly_cost_price`].value * 100,
          upfront_rrp: values[`${x}_upfront_rrp`].value * 100,
          monthly_rrp: values[`${x}_monthly_rrp`].value * 100,
        }

        const contractPricing = values.has_contract_pricing
          ? {
              upfront_cost_price_contract_3_year:
                values[`${x}_upfront_cost_price_contract_3_year`].value * 100,
              monthly_cost_price_contract_3_year:
                values[`${x}_monthly_cost_price_contract_3_year`].value * 100,
              upfront_rrp_contract_3_year:
                values[`${x}_upfront_rrp_contract_3_year`].value * 100,
              monthly_rrp_contract_3_year:
                values[`${x}_monthly_rrp_contract_3_year`].value * 100,
              upfront_cost_price_contract_5_year:
                values[`${x}_upfront_cost_price_contract_5_year`].value * 100,
              monthly_cost_price_contract_5_year:
                values[`${x}_monthly_cost_price_contract_5_year`].value * 100,
              upfront_rrp_contract_5_year:
                values[`${x}_upfront_rrp_contract_5_year`].value * 100,
              monthly_rrp_contract_5_year:
                values[`${x}_monthly_rrp_contract_5_year`].value * 100,
            }
          : {}

        return { ...basePricing, ...contractPricing }
      })

      body.additional_pricing = {
        wholesale: resellerTypePricing[0],
        vsp: resellerTypePricing[1],
        sp: resellerTypePricing[2],
      }
    }

    const result = await update(id, body)
    if (result.success) {
      queryClient.removeQueries('system-products')
      setLoading(false)
      props.history.push({
        pathname: '/products/system',
        state: {
          severity: 'success',
          message: result.message,
        },
      })
    } else {
      setErrors(result.errors)
      setLoading(false)
    }
  }

  const getDisplayPrice = price => {
    if (price === 0) {
      return price.toFixed(2)
    } else if (price > 0) {
      return (price / 100).toFixed(2)
    }

    return ''
  }

  if (hideContent) {
    return null
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom>
        Update System Product
      </Typography>
      <Grid item xl={6}>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Paper className={classes.paper}>
            <TextField
              variant='standard'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Name'
              name='name'
              defaultValue={values.name.value}
              autoFocus
              onChange={handleChange.bind(this)}
            />
            <TextField
              variant='standard'
              margin='normal'
              fullWidth
              disabled={!isOwner}
              id='description'
              label='Description'
              name='description'
              defaultValue={values.description.value}
              onChange={handleChange.bind(this)}
              multiline
              rows={4}
            />

            {!values.has_reseller_type_pricing && (
              <FormDefaultPricing
                isOwner={isOwner}
                values={values}
                setValues={setValues}
                handleChange={handleChange}
              />
            )}
          </Paper>

          {values.has_reseller_type_pricing && (
            <React.Fragment>
              <Typography variant='h5' gutterBottom>
                Reseller Type Settings
              </Typography>
              <Paper className={classes.paper}>
                <FormPricingByResellerType
                  isOwner={isOwner}
                  values={values}
                  setValues={setValues}
                  handleChange={handleChange}
                  style={{ width: '100%', marginTop: '-2rem' }}
                />
              </Paper>
            </React.Fragment>
          )}

          {isOwner && (
            <Paper className={classes.paper}>
              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel id='bill_upfront_monthly_label'>
                  Upfront Billed Monthly (over contract length)?
                </InputLabel>
                <Select
                  labelId='bill_upfront_monthly_label'
                  id='bill_upfront_monthly'
                  name='bill_upfront_monthly'
                  value={parseInt(values.bill_upfront_monthly.value) === 0 ? '0' : '1'}
                  onChange={handleChange}
                  label='Upfront Billed Monthly?'>
                  <MenuItem value='0'>No</MenuItem>
                  <MenuItem value='1'>Yes</MenuItem>
                </Select>
              </FormControl>
            </Paper>
          )}

          {errors.length > 0 && (
            <MuiAlert severity='error'>
              {errors.map((error, index) => {
                return <div key={index}>{error[1]}</div>
              })}
            </MuiAlert>
          )}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={loading}
            className={classes.submit}>
            Update
          </Button>
        </form>
      </Grid>
    </div>
  )
}

export default Form
