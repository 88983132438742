import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { listTags } from 'api/library/posts'
import { list as listCategories } from 'api/marketing-library/categories'
import { create } from 'api/marketing-library/posts'
import CreateButton from 'components/library/post/CreateButton'
import PostForm from 'components/library/post/PostForm'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext, useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { getFormData, uploadFiles } from 'helpers/library'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.breakpoints.width('lg'),
    margin: 'auto',
  },
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateAreas: '"column-2" "column-1"',

    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(4),
      gridTemplateAreas: '"column-1 column-2"',
      gridTemplateColumns: '8fr 4fr',
    },
  },
  column1: {
    gridArea: 'column-1',
  },
  column2: {
    gridArea: 'column-2',
  },
}))

function PostCreate(props) {
  const classes = useStyles()
  const { loading, setLoading } = useContext(LoadingContext)
  const { addToast } = useToasts()
  const [categories, setCategories] = useState({})
  const [tags, setTags] = useState({})
  const [values, setValues] = useState({
    name: '',
    content: '',
    image: {},
    featured: '',
    youtube_id: '',
    categories: [],
    tags: [],
    files: [],
  })
  const [errors, setErrors] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [catData, tagData] = await Promise.all([listCategories(1000), listTags()])
      setCategories(catData)
      setTags(tagData)
      setLoading(false)
    }

    fetchData()
  }, [setLoading])

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSave = async () => {
    setLoading(true)

    const data = getFormData(values)

    const res = await create(data)

    try {
      if (values.files) {
        await uploadFiles(res.post.data.id, values.files)

        if (res.success) {
          addToast('Saved Successfully', { appearance: 'success' })
          props.history.push(`/library/posts/${res.post.data.id}`)
        } else {
          setErrors(res.errors)
        }
      }
    } catch (e) {
      addToast('There was an error uploading your files', { appearance: 'error' })
    } finally {
      setLoading(false)
    }
  }

  if (!categories.data) {
    return null
  }

  return (
    <Box className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Create Post
      </Typography>

      <PostForm
        post=''
        categories={categories}
        tags={tags}
        values={values}
        errors={errors}
        handleChange={handleChange}
      />

      <Box className={classes.section}>
        <Box className={classes.column1}>
          <CreateButton loading={loading} handleSave={handleSave} />
        </Box>
      </Box>
    </Box>
  )
}

export default PostCreate
