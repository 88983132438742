import { IconButton } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ImageIcon from '@material-ui/icons/ImageOutlined'
import VideoIcon from '@material-ui/icons/PlayCircleFilled'
import DescriptionIcon from '@material-ui/icons/Notes'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import React, { useState } from 'react'
import HardwareImagePopover from './HardwareImagePopover'
import HardwareDescriptionPopover from './HardwareDescriptionPopover'

const useStyles = makeStyles(theme => ({
  header: {
    minHeight: '20px',
    paddingLeft: '34px',
    paddingRight: '34px',
  },
  actions: {
    marginTop: theme.spacing(3),
    minHeight: '60px',
    marginBottom: theme.spacing(3),
  },
  items: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: theme.spacing(3),
  },
  item: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
    position: 'relative',
    minWidth: '240px',
  },
  boxImage: {
    width: '100px',
    height: '100px',
    marginTop: theme.spacing(2),
    color: '#CCC',
    overflow: 'hidden',
    margin: 'auto',
  },
  boxText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '132px',
    margin: 'auto',
    wordBreak: 'break-word',
  },
  dark: {
    background: theme.palette.darkBlue,
  },
  descriptionModal: {
    background: '#fff',
    top: '50%',
    left: '50%',
    position: 'absolute',
    padding: theme.spacing(2),
  },
  light: {
    color: '#FFF',
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '0',
    top: '0',
    margin: theme.spacing(1),

    '& > button': {
      padding: 0,
      marginRight: theme.spacing(0.5),
    },

    '& > a': {
      padding: 0,
      marginRight: theme.spacing(0.5),
    },
  },
}))

function SummaryBox({
  variant,
  header,
  text,
  video_url,
  interop_url,
  image_url,
  image_thumb_url,
  description,
  className,
  ...props
}) {
  const classes = useStyles()
  const [imageAnchorEl, setImageAnchorEl] = useState(null)
  const [descriptionAnchorEl, setDescriptionAnchorEl] = useState(null)

  const handleImagePopoverOpen = event => {
    setImageAnchorEl(event.currentTarget)
  }

  const handleImagePopoverClose = () => {
    setImageAnchorEl(null)
  }

  const handleDescriptionPopoverOpen = event => {
    setDescriptionAnchorEl(event.currentTarget)
  }

  const renderVideoIcon = color => {
    if (video_url) {
      return (
        <Tooltip placement='top' title='Video'>
          <IconButton
            aria-label='play video'
            component={Link}
            href={video_url}
            target='_blank'
            rel='noopener noreferrer'>
            <VideoIcon style={{ fontSize: '1.6rem', color: color }} />
          </IconButton>
        </Tooltip>
      )
    }

    return null
  }

  const renderDescriptionIcon = color => {
    if (description) {
      return (
        <>
          <Tooltip placement='top' title='Description'>
            <IconButton
              aria-label='show description'
              onClick={handleDescriptionPopoverOpen}>
              <DescriptionIcon style={{ fontSize: '1.6rem', color: color }} />
            </IconButton>
          </Tooltip>
          <HardwareDescriptionPopover
            description={description}
            anchorEl={descriptionAnchorEl}
            setAnchorEl={setDescriptionAnchorEl}
          />
        </>
      )
    }
  }

  const renderInteropIcon = color => {
    if (interop_url) {
      return (
        <Tooltip placement='top' title='Interop'>
          <IconButton
            aria-label='interop details'
            component={Link}
            href={interop_url}
            target='_blank'
            rel='noopener noreferrer'>
            <SettingsEthernetIcon style={{ fontSize: '1.6rem', color: color }} />
          </IconButton>
        </Tooltip>
      )
    }

    return null
  }

  const renderHeader = className => {
    return typeof header === 'string' ? (
      <Typography variant='h6' className={className}>
        {header}
      </Typography>
    ) : (
      <div style={{ fontSize: 18 }} className={className}>
        {header}
      </div>
    )
  }

  const textVariant = () => {
    return (
      <Paper className={`${classes.item} ${classes.dark} ${className}`}>
        {renderHeader(`${classes.header} ${classes.light}`)}

        <div className={classes.icons}>
          {renderVideoIcon('#fff')}
          {renderInteropIcon('#fff')}
          {renderDescriptionIcon('#fff')}
        </div>

        <div className={classes.boxText}>
          {typeof text === 'string' ? (
            <Typography
              style={{ display: 'inline-block', color: '#FFF', fontSize: '1.5rem' }}
              variant='h4'>
              {text}
            </Typography>
          ) : (
            <div
              style={{ display: 'inline-block', color: '#FFF', fontSize: '1.5rem' }}
              variant='h4'>
              {text}
            </div>
          )}
        </div>

        <div className={classes.actions}>{props.children}</div>
      </Paper>
    )
  }

  const imageVariant = () => {
    return (
      <Paper className={classes.item}>
        {renderHeader(`${classes.header}`)}

        <div className={classes.icons}>
          {renderVideoIcon('#43425D')}
          {renderInteropIcon('#43425D')}
          {renderDescriptionIcon('#43425D')}
        </div>

        {image_url && (
          <>
            <div
              className={classes.boxImage}
              style={{
                backgroundImage: `url(${image_thumb_url})`,
                backgroundSize: '100px',
                backgroundRepeat: 'no-repeat',
                marginBottom: '30px',
              }}
              onClick={handleImagePopoverOpen}
              onMouseLeave={handleImagePopoverClose}></div>
            <HardwareImagePopover
              imageUrl={image_url}
              anchorEl={imageAnchorEl}
              setAnchorEl={setImageAnchorEl}
            />
          </>
        )}

        {!image_url && <ImageIcon className={classes.boxImage} />}

        <div className={classes.actions}>{props.children}</div>
      </Paper>
    )
  }

  return variant === 'text' ? textVariant() : imageVariant()
}

export default SummaryBox
