import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
  Tooltip,
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Info } from '@material-ui/icons'
import Check from '@material-ui/icons/Check'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import { Alert } from '@material-ui/lab'
import { DatePicker } from '@material-ui/pickers'
import { AuthContext } from 'contexts/AuthContext'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import { LoadingContext } from 'contexts/LoadingContext'
import { format } from 'date-fns'
import { useContext, useEffect, useRef, useState } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    position: 'relative',
  },
  valueCell: {
    width: '50%',
  },
  grey: {
    color: '#909090',
    textDecoration: 'line-through',
  },
  addNote: {
    position: 'absolute',
    top: '2em',
    right: '2em',
  },
  message: {
    width: '100%',
  },
  red: {
    backgroundColor: '#f44336',
    '& > *': {
      color: '#fff!important',
    },
    '& svg': {
      color: '#fff!important',
    },
  },
}))

function SummaryCopyPaste() {
  const { state } = useContext(GeographicPortContext)

  return (
    <>
      {state.trunk_selection.value === 'uk_pci' && (
        <Alert color='warning'>Note: This is a UK PCI port</Alert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} xl={6}>
          <NumberDetails />
          <CustomerInstallationDetails />
          <ContactDetails />
        </Grid>

        <Grid item xs={12} xl={6}>
          <PciDetails />
          <PortDate />
          <CommunicationProvider />
          <MainNumberDetails />
          <Notes></Notes>
        </Grid>
      </Grid>
    </>
  )
}

function Note({ note }) {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const [content, setContent] = useState(note.content)
  const { deletePortNote, editPortNote } = useContext(GeographicPortContext)
  const { setLoading } = useContext(LoadingContext)
  const { user } = useContext(AuthContext)
  const inputRef = useRef(null)

  const handleDeleteNote = async () => {
    setLoading(true)
    await deletePortNote(note.id)
    setLoading(false)
  }

  const handleSaveNote = async () => {
    setEditing(false)
    setLoading(true)
    await editPortNote(note.id, content)
    setLoading(false)
  }

  const handleEditNote = () => {
    setEditing(true)
  }

  const handleCancel = () => {
    setEditing(false)
  }

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing, inputRef])

  return (
    <>
      <ListItem alignItems='flex-start' key={note.id}>
        <ListItemAvatar>
          <Avatar alt={note.user.name} src={`/api/${note.user.avatar}`} />
        </ListItemAvatar>
        {editing ? (
          <>
            <TextareaAutosize
              style={{ width: '100%' }}
              minRows={4}
              value={content}
              maxLength={511}
              ref={inputRef}
              onChange={event => {
                setContent(event.target.value)
              }}
            />
            <br />
            <Button onClick={handleSaveNote}>Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </>
        ) : (
          <>
            <ListItemText
              className={classes.message}
              secondary={
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: note.content.replaceAll('\n', '<br />'),
                    }}
                  />

                  <br />
                  <br />
                  <span className={classes.messageDate}>{note.created_at.datetime}</span>
                </>
              }
              primary={note.user.name + ' - ' + note.created_at.readable}
            />
            {user.id === note.user.id && !note.deleted_at.datetime && (
              <>
                <IconButton edge='end' aria-label='comments' onClick={handleEditNote}>
                  <Edit />
                </IconButton>
                <IconButton edge='end' aria-label='comments' onClick={handleDeleteNote}>
                  <Delete />
                </IconButton>
              </>
            )}
          </>
        )}
      </ListItem>

      <Divider variant='inset' component='li' />
    </>
  )
}

function Notes() {
  const classes = useStyles()
  const { state, addPortNote } = useContext(GeographicPortContext)
  const { setLoading } = useContext(LoadingContext)

  const [newNote, setNewNote] = useState(null)
  const [editing, setEditing] = useState(false)

  const inputRef = useRef(null)

  const handleAddNote = () => {
    setNewNote('')
    setEditing(true)
  }

  const handleCancel = () => {
    setNewNote(null)
    setEditing(false)
  }

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing, inputRef])

  const saveNewNote = () => {
    setEditing(false)
    setLoading(true)
    addPortNote(newNote).then(note => {
      setNewNote(null)
      setLoading(false)
    })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Notes
      </Typography>

      {!editing ? (
        <Button className={classes.addNote} onClick={handleAddNote}>
          Add Note
        </Button>
      ) : (
        <>
          <TextareaAutosize
            value={newNote}
            style={{ width: '100%' }}
            maxLength={511}
            ref={inputRef}
            minRows={4}
            onChange={event => {
              setNewNote(event.target.value)
            }}></TextareaAutosize>
          <Button onClick={saveNewNote}>Save</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </>
      )}
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {state.notes.map(note => (
          <Note note={note} key={note.id} />
        ))}
      </List>
    </Paper>
  )
}

function NumberDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  const [checkedMainNumber, setCheckedMainNumber] = useState(false)
  const [checkedDdiRanges, setCheckedDdiRanges] = useState([])
  const [checkedAssocNumbers, setCheckedAssocNumbers] = useState([])
  const [checkedSingleNumbers, setCheckedSingleNumbers] = useState([])
  const [checkedOtherNumbers, setCheckedOtherNumbers] = useState([])

  const handleChangeCheckedDdiRanges = (checked, value) => {
    const values = Array.from(checkedDdiRanges)
    const index = values.indexOf(value)
    checked ? values.push(value) : index !== -1 && values.splice(index, 1)
    setCheckedDdiRanges(values)
  }

  const handleChangeCheckedAssocNumbers = (checked, value) => {
    const values = Array.from(checkedAssocNumbers)
    const index = values.indexOf(value)
    checked ? values.push(value) : index !== -1 && values.splice(index, 1)
    setCheckedAssocNumbers(values)
  }

  const handleChangeCheckedSingleNumbers = (checked, value) => {
    const values = Array.from(checkedSingleNumbers)
    const index = values.indexOf(value)
    checked ? values.push(value) : index !== -1 && values.splice(index, 1)
    setCheckedSingleNumbers(values)
  }

  const handleChangeCheckedOtherNumbers = (checked, value) => {
    const values = Array.from(checkedOtherNumbers)
    const index = values.indexOf(value)
    checked ? values.push(value) : index !== -1 && values.splice(index, 1)
    setCheckedOtherNumbers(values)
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Number Details
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <CheckableCell checked={checkedMainNumber} className={classes.valueCell}>
                {state.number_details.std_code.value}
                {state.number_details.main_number.local_number.value}
              </CheckableCell>
              <CheckableCell checked={checkedMainNumber}>Main Number</CheckableCell>
              <TableCell>{state.number_details.main_number.action.value}</TableCell>
              <TableCell>
                <Checkbox
                  onChange={e => setCheckedMainNumber(e.target.checked)}
                  color='primary'
                />
              </TableCell>
            </TableRow>

            {state.number_details.ddi_ranges.map((x, index) => (
              <TableRow key={`ddi-ranges-${x.local_number_start.value}`}>
                <CheckableCell
                  checked={checkedDdiRanges.indexOf(index) !== -1}
                  className={classes.valueCell}>
                  {x.std_code.value}
                  {x.local_number_start.value} - {x.std_code.value}
                  {x.local_number_end.value}
                </CheckableCell>
                <CheckableCell checked={checkedDdiRanges.indexOf(index) !== -1}>
                  DDI Range
                </CheckableCell>
                <TableCell>{x.action.value}</TableCell>
                <TableCell>
                  <Checkbox
                    onChange={e => handleChangeCheckedDdiRanges(e.target.checked, index)}
                    color='primary'
                  />
                </TableCell>
              </TableRow>
            ))}

            {state.number_details.associated_numbers.map((x, index) => (
              <TableRow key={`assoc-numbers-${x.local_number.value}`}>
                <CheckableCell
                  checked={checkedAssocNumbers.indexOf(index) !== -1}
                  className={classes.valueCell}>
                  {x.std_code.value}
                  {x.local_number.value}
                </CheckableCell>
                <CheckableCell checked={checkedAssocNumbers.indexOf(index) !== -1}>
                  Associated Numbers
                </CheckableCell>
                <TableCell>{x.action.value}</TableCell>
                <TableCell>
                  <Checkbox
                    onChange={e =>
                      handleChangeCheckedAssocNumbers(e.target.checked, index)
                    }
                    color='primary'
                  />
                </TableCell>
              </TableRow>
            ))}

            {state.number_details.single_number_ddis.map((x, index) => (
              <TableRow key={`single-numbers-${x.local_number.value}`}>
                <CheckableCell
                  checked={checkedSingleNumbers.indexOf(index) !== -1}
                  className={classes.valueCell}>
                  {x.std_code.value}
                  {x.local_number.value}
                </CheckableCell>
                <CheckableCell checked={checkedSingleNumbers.indexOf(index) !== -1}>
                  Single Number DDIs
                </CheckableCell>
                <TableCell> {x.action.value}</TableCell>
                <TableCell>
                  <Checkbox
                    onChange={e =>
                      handleChangeCheckedSingleNumbers(e.target.checked, index)
                    }
                    color='primary'
                  />
                </TableCell>
              </TableRow>
            ))}

            {state.number_details.other_numbers.map((x, index) => (
              <TableRow key={`other-numbers-${x.local_number.value}`}>
                <CheckableCell
                  checked={checkedOtherNumbers.indexOf(index) !== -1}
                  className={classes.valueCell}>
                  {x.std_code.value}
                  {x.local_number.value}
                </CheckableCell>
                <CheckableCell checked={checkedOtherNumbers.indexOf(index) !== -1}>
                  Other Numbers
                </CheckableCell>
                <TableCell>{x.action.value}</TableCell>
                <TableCell>
                  <Checkbox
                    onChange={e =>
                      handleChangeCheckedOtherNumbers(e.target.checked, index)
                    }
                    color='primary'
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function CheckableCell({ className, checked, children }) {
  const classes = useStyles()

  return (
    <TableCell className={`${className} ${checked ? classes.grey : ''}`}>
      {children}
    </TableCell>
  )
}

function CustomerInstallationDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Customer Installation Details
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Premises</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.address_details.premises.value}
                  dispatchkey='premises'
                  action='SET_ADDRESS_DETAILS'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Street</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.address_details.street.value}
                  dispatchkey='street'
                  action='SET_ADDRESS_DETAILS'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Town</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.address_details.town.value}
                  dispatchkey='town'
                  action='SET_ADDRESS_DETAILS'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>County</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.address_details.county.value}
                  dispatchkey='county'
                  action='SET_ADDRESS_DETAILS'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Postcode</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.address_details.post_code.value}
                  dispatchkey='post_code'
                  action='SET_ADDRESS_DETAILS'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.customer.company_name.value}
                  dispatchkey='company_name'
                  action='SET_CUSTOMER'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.customer.first_name.value}
                  dispatchkey='first_name'
                  action='SET_CUSTOMER'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.customer.last_name.value}
                  dispatchkey='last_name'
                  action='SET_CUSTOMER'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Number</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.customer.contact_number.value}
                  dispatchkey='contact_number'
                  action='SET_CUSTOMER'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.customer.email.value}
                  dispatchkey='email'
                  action='SET_CUSTOMER'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Customer Changing Address?</TableCell>
              <TableCell className={classes.valueCell}>
                {state.address_details.is_moving.value ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Old Postcode</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.address_details.old_post_code.value ?? ''}
                  dispatchkey='old_post_code'
                  action='SET_ADDRESS_DETAILS'
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function ContactDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Contact Details
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Request Created By</TableCell>
              <TableCell className={classes.valueCell}>{state.created_by.name}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.contact.name.value}
                  dispatchkey='name'
                  action='SET_CONTACT_DETAILS'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Email</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.contact.email.value}
                  dispatchkey='email'
                  action='SET_CONTACT_DETAILS'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Contact Phone Number</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.contact.phone_number.value}
                  dispatchkey='phone_number'
                  action='SET_CONTACT_DETAILS'
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function PortDate() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Port Date
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Earliest Port Date</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableDate
                  initialValue={state.port_date.value}
                  action='SET_PORT_DATE'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.port_date_type.value.replace(/_/g, ' ')}
                  action='SET_PORT_DATE_TYPE'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.port_date_time.value?.replace('SLOT_', '')}
                  action='SET_PORT_DATE_TIME'
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function CommunicationProvider() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Communication Provider
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Porting Numbers From</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={
                    state.lcp.value +
                    (state.lcp_other.value ? `/${state.lcp_other.value}` : '')
                  }
                  action='SET_LCP'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Account Number</TableCell>
              <TableCell className={classes.valueCell}>
                {state.lcp_account_ref.value}
                <PortDetailEditableText
                  initialValue={state.lcp_account_ref.value}
                  action='SET_LCP_ACCOUNT_REF'
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function MainNumberDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Main Number Details
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                Line Type{' '}
                <Tooltip
                  title={
                    <>
                      <Box marginBottom={1}>
                        A "Single line" port is a number that terminates on a single
                        socket and has no other associated numbers, whereas a "Multi line"
                        port terminates on equipment like a PBX, though it can be a single
                        telephone number that goes to multiple lines.
                      </Box>
                      <Box>
                        Some BT products that are attached to telephone lines
                        automatically make them a "Multi line".
                      </Box>
                    </>
                  }
                  placement='right'>
                  <IconButton color='default' size='small'>
                    <Info />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.number_details.line_type.value.toUpperCase()}
                  action='SET_LINE_TYPE'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Products Associated?</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableDropdown
                  initialValue={state.number_details.has_associated_products.value}
                  action='SET_HAS_ASSOCIATED_PRODUCTS'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Has Remote Call Forwarding?</TableCell>
              <TableCell className={classes.valueCell}>
                {state.number_details.has_rcf.value ? 'Yes' : 'No'}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>RCF Post code</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableText
                  initialValue={state.number_details.rcf_post_code.value ?? ''}
                  action='SET_RCF_POST_CODE'
                />
              </TableCell>
            </TableRow>

            {state.number_details.has_rcf.value && (
              <TableRow>
                <TableCell>Line Has Remote Call Forwarding?</TableCell>
                <TableCell className={classes.valueCell}>
                  {state.number_details.rcf_post_code.value ? 'Yes' : 'No'}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>Featurenet Order?</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableDropdown
                  initialValue={state.number_details.is_featurenet_port.value}
                  action='SET_IS_FEATURENET_PORT'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function PciDetails() {
  const classes = useStyles()
  const { state } = useContext(GeographicPortContext)

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Trunk Selection
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow
              className={state.trunk_selection.value === 'uk_pci' ? classes.red : ''}>
              <TableCell>Trunk</TableCell>
              <TableCell className={classes.valueCell}>
                <PortDetailEditableDropdown
                  initialValue={state.trunk_selection.value}
                  action='SET_TRUNK_SELECTION'
                  options={[
                    { label: 'UK - Standard', value: 'uk_standard' },
                    { label: 'UK - PCI', value: 'uk_pci' },
                    { label: 'ROI - Standard', value: 'roi_standard' },
                  ]}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

function PortDetailEditableDropdown({
  initialValue,
  dispatchkey = null,
  action,
  options,
}) {
  const { dispatch, manualUpdate } = useContext(GeographicPortContext)
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [isEditing, setIsEditing] = useState(false)

  const inputRef = useRef(null)

  const handleChange = e => {
    setValue(e.target.value)
    dispatch({
      type: action,
      payload: e.target.value,
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await manualUpdate()
    setLoading(false)
    setIsEditing(false)
  }

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus()
    }
  }, [isEditing, inputRef])

  if (user.type === 'distributor') {
    return (
      <Box display='flex' alignItems='center'>
        <Box width={220}>
          <Select
            style={{ display: isEditing ? 'block' : 'none' }}
            value={value}
            inputRef={inputRef}
            onChange={handleChange}
            onBlur={handleSubmit}
            disabled={loading}>
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Box style={{ display: !isEditing ? 'block' : 'none' }}>
            {options.find(option => option.value === value)?.label}
          </Box>
        </Box>

        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {isEditing ? (
              <IconButton size='small' color='default' onClick={handleSubmit}>
                <Check />
              </IconButton>
            ) : (
              <IconButton
                size='small'
                color='default'
                onClick={() => {
                  setIsEditing(true)
                }}>
                <Edit />
              </IconButton>
            )}
          </>
        )}
      </Box>
    )
  } else {
    return <div>{value}</div>
  }
}

function PortDetailEditableText({ initialValue, dispatchkey = null, action }) {
  const { dispatch, manualUpdate } = useContext(GeographicPortContext)
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [isEditing, setIsEditing] = useState(false)

  const inputRef = useRef(null)

  const handleChange = e => {
    setValue(e.target.value)
    dispatch({
      type: action,
      payload: dispatchkey
        ? { key: dispatchkey, value: e.target.value ?? null }
        : (e.target.value ?? null),
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await manualUpdate()
    setLoading(false)
    setIsEditing(false)
  }

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus()
    }
  }, [isEditing, inputRef])

  if (user.type === 'distributor') {
    return (
      <Box display='flex' alignItems='center'>
        <Box width={220}>
          <TextField
            style={{ display: isEditing ? 'block' : 'none' }}
            value={value}
            inputRef={inputRef}
            onChange={handleChange}
            onBlur={handleSubmit}
            disabled={loading}
          />
          <Box style={{ display: !isEditing ? 'block' : 'none' }}>{value}</Box>
        </Box>

        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {isEditing ? (
              <IconButton size='small' color='default' onClick={handleSubmit}>
                <Check />
              </IconButton>
            ) : (
              <IconButton
                size='small'
                color='default'
                onClick={() => {
                  setIsEditing(true)
                }}>
                <Edit />
              </IconButton>
            )}
          </>
        )}
      </Box>
    )
  } else {
    return <div>{value}</div>
  }
}

function PortDetailEditableDate({ initialValue, dispatchkey = null, action }) {
  const { dispatch, manualUpdate } = useContext(GeographicPortContext)
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [isEditing, setIsEditing] = useState(false)

  const handleChange = async input => {
    const date = input ? format(input, 'yyyy-MM-dd') : null
    setValue(date)
    dispatch({
      type: action,
      payload: dispatchkey ? { key: dispatchkey, value: date ?? null } : (date ?? null),
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    await manualUpdate()

    setLoading(false)
    setIsEditing(false)
  }

  if (user.type === 'distributor') {
    return (
      <Box display='flex' alignItems='center'>
        <Box width={220}>
          {isEditing && (
            <DatePicker
              style={{ display: isEditing ? 'block' : 'none' }}
              label='Earliest Port Date'
              disablePast={true}
              inputVariant='standard'
              format='dd/MM/yyyy'
              fullWidth
              clearable
              value={value}
              onChange={handleChange}
            />
          )}
          <Box style={{ display: !isEditing ? 'block' : 'none' }}>{value || 'TBC'}</Box>
        </Box>

        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {isEditing ? (
              <IconButton size='small' color='default' onClick={handleSubmit}>
                <Check />
              </IconButton>
            ) : (
              <IconButton
                size='small'
                color='default'
                onClick={() => {
                  setIsEditing(true)
                }}>
                <Edit />
              </IconButton>
            )}
          </>
        )}
      </Box>
    )
  } else {
    return <div>{value}</div>
  }
}

export default SummaryCopyPaste
