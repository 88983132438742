import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { GeographicPortContext } from 'contexts/GeographicPortContext'
import { useContext } from 'react'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  portDateTypeLabel: {
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    gap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap-reverse',
    },
    [theme.breakpoints.up('md')]: {
      flexWrap: 'no-wrap',
    },
  },
}))

function TrunkSelection() {
  const classes = useStyles()
  const { state, dispatch } = useContext(GeographicPortContext)

  const handleChange = event => {
    dispatch({
      type: 'SET_TRUNK_SELECTION',
      payload: event.target.value,
    })
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5' gutterBottom>
        Trunk Selection
      </Typography>

      <Box mb={4} className={classes.container}>
        <FormControl component='fieldset' style={{ minWidth: '380px' }}>
          <FormLabel component='legend' className={classes.portDateTypeLabel}>
            Which trunk would you like this number ported to?
          </FormLabel>
          <Select
            labelId='featured_label'
            id='featured'
            name='featured'
            value={state.trunk_selection.value}
            onChange={handleChange}
            label='Featured'>
            <MenuItem value='uk_standard'>UK - Standard</MenuItem>
            <MenuItem value='uk_pci'>UK - PCI</MenuItem>
          </Select>
        </FormControl>

        <div>
          <Alert severity='info'>
            <Typography gutterBottom>
              <strong>UK - Standard</strong>
              <br />
              For UK port orders which are not related to PCI requests.
            </Typography>

            <Typography gutterBottom>
              <strong>UK - PCI</strong>
              <br />
              For port requests that are linked with orders for the PCI service. The PCI
              for iPECS Cloud service is the Payment Card Industry Data Security Standard
              for securely handling card payments taken over the phone.
            </Typography>
          </Alert>
        </div>
      </Box>
    </Paper>
  )
}

export default TrunkSelection
