import DateFnsUtils from '@date-io/date-fns'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ScrollToTop from 'components/ScrollToTop'
import config from 'config'
import NotificationContext from 'contexts/NotificationContext'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import App from './App'
import AuthContext from './contexts/AuthContext'
import FeatureTogglesContext from './contexts/FeatureToggleContext'
import HelpContext from './contexts/HelpContext'
import LoadingContext from './contexts/LoadingContext'
import MainNavContext from './contexts/MainNavContext'
import ViewContext from './contexts/ViewContext'
import './i18n'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createIDBPersister } from './IDBPersister'

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  })
}

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: red,
    lightBlue: '#EEEEF7',
    darkBlue: '#43425D',
    muted: '#b3b3b3',
  },
  overrides: {
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '80%',
        height: '100%',
      },
      shrink: {
        maxWidth: '100%',
      },
    },
  },
})

const muiAlertToast = ({ appearance, children }) => (
  <MuiAlert
    style={{ marginBottom: '12px' }}
    elevation={6}
    variant='filled'
    severity={appearance}>
    {children}
  </MuiAlert>
)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5000, // 5 seconds
      cacheTime: 1000 * 60 * 60 * 24 * 5, // 5 days, this should be higher than maxAge on persistent cache
    },
  },
})

const persister = createIDBPersister('pragmahub')

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <LoadingContext>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: persister,
            maxAge: 1000 * 60 * 60 * 24 * 5, // 3 days
            hydrateOptions: {},
            dehydrateOptions: {
              shouldDehydrateQuery: ({ queryKey, state }) => {
                return state.status === 'success'
              },
            },
          }}>
          <AuthContext>
            <Sentry.ErrorBoundary fallback={'An error has occurred'}>
              <MainNavContext>
                <FeatureTogglesContext>
                  <ToastProvider
                    components={{ Toast: muiAlertToast }}
                    autoDismiss={true}
                    placement='bottom-center'>
                    <HelpContext>
                      <ViewContext>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <NotificationContext>
                            <App />
                          </NotificationContext>
                        </MuiPickersUtilsProvider>
                      </ViewContext>
                    </HelpContext>
                  </ToastProvider>
                </FeatureTogglesContext>
              </MainNavContext>
            </Sentry.ErrorBoundary>
          </AuthContext>
        </PersistQueryClientProvider>
      </LoadingContext>
    </ThemeProvider>
    <ScrollToTop />
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
