import { IconButton, Tooltip } from '@material-ui/core'
import DownIcon from '@material-ui/icons/ArrowDownward'
import UpIcon from '@material-ui/icons/ArrowUpward'
import PropTypes from 'prop-types'

function ProductMoveUpDown({ productId, onMoveUp, onMoveDown }) {
  return (
    <>
      <Tooltip title='Move Up' placement='top'>
        <IconButton color='default' onClick={() => onMoveUp(productId)}>
          <UpIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title='Move Down' placement='top'>
        <IconButton color='default' onClick={() => onMoveDown(productId)}>
          <DownIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

ProductMoveUpDown.propTypes = {
  productId: PropTypes.string.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
}

export default ProductMoveUpDown
