import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { payment as paymentSave, summary as fetchSummary } from 'api/quote-builder'
import MiscQuoteItem from 'components/quotes/MiscQuoteItem'
import { QuoteContext } from 'contexts/QuoteContext'
import { getPounds, getPence } from 'helpers/currency'
import PropTypes from 'prop-types'
import { React, useContext, useState } from 'react'
import EditActions from './EditActions'
import QuoteSummaryWithLeasing from './QuoteSummaryWithLeasing'
import QuoteSummaryWithoutLeasing from './QuoteSummaryWithoutLeasing'
import SummaryBox from './SummaryBox'

const useStyles = makeStyles(theme => ({
  summary: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
  },
  items: {
    marginTop: '2rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(auto-fit, 240px)',
    },
  },
  summaryGreen: {
    backgroundColor: theme.palette.success.main,
  },
  summaryBlue: {
    backgroundColor: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    padding: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    minWidth: '74px',
  },
  circleLight: {
    backgroundColor: theme.palette.lightBlue,
    color: '#000',
    padding: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    minWidth: '74px',
  },
  transparentText: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  summaryText: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: '#FFF',
  },
  loader: {
    marginTop: theme.spacing(12),
    textAlign: 'center',
  },
  accordionDetails: {
    backgroundColor: theme.palette.lightBlue,
    display: 'block',
  },
  whiteTextField: {
    '& .MuiInputLabel-root': {
      color: '#FFF', // Label
    },
    '& .MuiInputBase-input': {
      color: '#FFF', // Text color
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFF9', // Solid underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFF', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF', // Solid underline on focus
    },
  },
}))

function QuoteSummary({ selectedView, priceBy, summaryData, leaseBoxes, ...props }) {
  const classes = useStyles()
  const { state } = useContext(QuoteContext)

  const hasImage = item => {
    return item.hasOwnProperty('image_thumb_url') && item.image_thumb_url
  }

  const showExternalView = () => {
    return (
      selectedView === 'External' ||
      priceBy === 'user' ||
      state.rawData.status !== 'created'
    )
  }

  return (
    <div>
      {state.rawData.status === 'created' && <MiscQuoteItem />}

      <div className={classes.items}>
        <>
          {summaryData.hardware.items.map((item, index) => {
            return (
              <SummaryBox
                key={index}
                header={item.display_name}
                video_url={item.video_url ?? null}
                interop_url={item.interop_url ?? null}
                image_url={item.image_url ?? null}
                image_thumb_url={item.image_thumb_url ?? null}>
                {showExternalView() ? (
                  <div className={classes.circle}>
                    <Typography variant='h6'>{item.quantity}</Typography>
                  </div>
                ) : (
                  <EditActions item={item} />
                )}
              </SummaryBox>
            )
          })}

          {summaryData.services.items.map((item, index) => {
            return (
              <SummaryBox
                key={index}
                text={item.display_name}
                header={hasImage(item) ? item.name : ''}
                variant={hasImage(item) ? 'image' : 'text'}
                interop_url={item.interop_url ?? null}
                image_url={item.image_url ?? null}
                image_thumb_url={item.image_thumb_url ?? null}>
                {showExternalView() ? (
                  <div className={classes.circle}>
                    <Typography variant='h6'>{item.quantity}</Typography>
                  </div>
                ) : (
                  <EditActions variant={hasImage(item) ? 'dark' : 'light'} item={item} />
                )}
              </SummaryBox>
            )
          })}

          {summaryData.early_settlement.items.map((item, index) => {
            return (
              <SummaryBox key={index} text={item.name} variant='text'>
                <div className={classes.circleLight}>
                  <Typography variant='h6'>
                    {' '}
                    {'£' +
                      Math.round(item.upfront_rrp / 100).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </Typography>
                </div>
              </SummaryBox>
            )
          })}
        </>
      </div>

      <div className={classes.summary}>
        {summaryData.summary.totals.monthly_saving > 0 && (
          <SummaryBox
            className={classes.summaryGreen}
            header='Saving'
            text={
              <>
                <Typography variant='h2'>
                  {'£' + getPounds(summaryData.summary.totals.monthly_saving)}
                </Typography>
                <Typography variant='body1'>
                  .{getPence(summaryData.summary.totals.monthly_saving)}
                </Typography>
                <Typography className={classes.transparentText} variant='caption'>
                  Monthly Saving
                </Typography>
              </>
            }
            variant='text'>
            <Typography className={classes.summaryText} variant='body1'>
              Starter features for your business to grow.
            </Typography>
          </SummaryBox>
        )}

        <SummaryBox
          className={classes.summaryBlue}
          header='Upfront'
          text={
            <>
              <Typography variant='h2'>
                {'£' + getPounds(summaryData.summary.totals.upfront_rrp)}
              </Typography>
              <Typography variant='body1'>
                .{getPence(summaryData.summary.totals.upfront_rrp)}
              </Typography>
              <Typography className={classes.transparentText} variant='caption'>
                One Off Payment
              </Typography>
            </>
          }
          variant='text'>
          <Typography className={classes.summaryText} variant='body1'>
            This will include install costs and hardware outside of a lease
          </Typography>
        </SummaryBox>

        {leaseBoxes ? (
          <QuoteSummaryWithLeasing summaryData={summaryData} />
        ) : (
          <QuoteSummaryWithoutLeasing summaryData={summaryData} />
        )}

        {selectedView === 'Internal' && (
          <>
            {summaryData.price_per_user.target && (
              <PricePerUser summaryData={summaryData} />
            )}

            {summaryData.profit !== undefined && (
              <SummaryBox
                header='Total Profit'
                text={
                  <>
                    <Typography variant='h2'>
                      {'£' + getPounds(summaryData.profit)}
                    </Typography>
                    <Typography variant='body1'>
                      .{getPence(summaryData.profit)}
                    </Typography>
                    <Typography className={classes.transparentText} variant='caption'>
                      Monthlies calculated over {summaryData.contract_length} months.
                    </Typography>
                  </>
                }
                variant='text'>
                <Typography className={classes.summaryText} variant='body1'>
                  Total profit for upfront and monthly
                </Typography>
              </SummaryBox>
            )}

            {summaryData.upfront_profit !== undefined && (
              <SummaryBox
                header='Upfront Profit'
                text={
                  <>
                    <Typography variant='h2'>
                      {'£' + getPounds(summaryData.upfront_profit)}
                    </Typography>
                    <Typography variant='body1'>
                      .{getPence(summaryData.upfront_profit)}
                    </Typography>
                    <Typography className={classes.transparentText} variant='caption'>
                      Total upfront profit
                    </Typography>
                  </>
                }
                variant='text'></SummaryBox>
            )}

            {summaryData.monthly_profit !== undefined && (
              <SummaryBox
                header='Monthly Profit'
                text={
                  <>
                    <Typography variant='h2'>
                      {'£' + getPounds(summaryData.monthly_profit)}
                    </Typography>
                    <Typography variant='body1'>
                      .{getPence(summaryData.monthly_profit)}
                    </Typography>
                    <Typography className={classes.transparentText} variant='caption'>
                      Total monthly profit
                    </Typography>
                  </>
                }
                variant='text'></SummaryBox>
            )}

            {summaryData.cost_price !== undefined && (
              <SummaryBox
                header='Cost Price'
                text={
                  <>
                    <Typography variant='h2'>
                      {'£' + getPounds(summaryData.cost_price)}
                    </Typography>
                    <Typography variant='body1'>
                      .{getPence(summaryData.cost_price)}
                    </Typography>
                    <Typography className={classes.transparentText} variant='caption'>
                      Monthlies calculated over {summaryData.contract_length} months.
                    </Typography>
                  </>
                }
                variant='text'>
                <Typography className={classes.summaryText} variant='body1'>
                  Total cost price for upfront and monthly
                </Typography>
              </SummaryBox>
            )}
          </>
        )}
      </div>

      {props.children}
    </div>
  )
}

function PricePerUser({ summaryData }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [ppuTarget, setPpuTarget] = useState(summaryData.price_per_user.target / 100)

  const { state, dispatch } = useContext(QuoteContext)

  const hasChanged = () => {
    return ppuTarget !== summaryData.price_per_user.target / 100
  }

  const handleChange = event => {
    setPpuTarget(event.target.value)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    await paymentSave(state.id, {
      leasing: state.payment.leasing.value,
      early_settlement: state.payment.early_settlement.value,
      contract_length: state.payment.contract_length.value,
      discount_code: state.payment.discount_code.value,
      price_per_user: ppuTarget,
    })

    dispatch({
      type: 'SET_PAYMENT',
      payload: {
        ...state.payment,
        price_per_user: { value: ppuTarget, error: '' },
      },
    })

    const summary = await fetchSummary(state.id)
    dispatch({ type: 'SET_SUMMARY', payload: summary })

    setLoading(false)
  }

  return (
    <SummaryBox
      header='Price Per User'
      text={
        <>
          <Typography variant='h2'>
            {'£' + getPounds(summaryData.price_per_user.actual)}
          </Typography>
          <Typography variant='body1'>
            .{getPence(summaryData.price_per_user.actual)}
          </Typography>
        </>
      }
      variant='text'>
      <form onSubmit={handleSubmit}>
        <TextField
          className={classes.whiteTextField}
          variant='standard'
          margin='normal'
          required
          fullWidth
          label='Target Price Per User'
          name='ppu'
          disabled={loading}
          value={ppuTarget}
          onChange={handleChange}
        />

        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          disabled={loading || !hasChanged()}>
          Save
        </Button>
      </form>
    </SummaryBox>
  )
}

QuoteSummary.propTypes = {
  selectedView: PropTypes.string,
  priceBy: PropTypes.string,
  leaseBoxes: PropTypes.bool,
}

QuoteSummary.defaultProps = {
  selectedView: 'External',
  priceBy: '',
  leaseBoxes: false,
}

export default QuoteSummary
