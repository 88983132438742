import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { CloudDownloadOutlined, Videocam } from '@material-ui/icons'
import { posts as list } from 'api/library/categories'
import Link from 'components/Link'
import { LoadingContext } from 'contexts/LoadingContext'
import React, { useContext } from 'react'
import TextContentRenderer from 'components/TextContentRenderer'

const useStyles = makeStyles(theme => ({
  pagination: {
    marginBottom: '60px',
  },
  tableRow: {
    cursor: 'pointer',
  },
  categoryCard: {
    height: '100%',
    position: 'relative',
    background: theme.palette.darkBlue,
    '& *': {
      color: '#FFF',
    },
  },
  postCard: {
    height: '100%',
    position: 'relative',
  },
  categoryPush: {
    height: '50px',
  },
  categoryActions: {
    height: '50px',
    position: 'absolute',
    bottom: '0',
  },
  categories: {
    display: 'grid',
    gridGap: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}))

function PostList({ id, posts, setPosts, linkPrefix }) {
  const classes = useStyles()
  const { setLoading } = useContext(LoadingContext)

  const handleChangePage = async (event, newPage) => {
    setLoading(true)
    setPosts(await list(id, parseInt(posts.meta.per_page), parseInt(newPage + 1)))
    setLoading(false)
  }

  const handleChangeRowsPerPage = async event => {
    setLoading(true)
    setPosts(await list(id, parseInt(event.target.value, 10)))
    setLoading(false)
  }

  return (
    <React.Fragment>
      <TextContentRenderer content={posts.meta.category.description} />

      {posts.meta.category.sub_categories.length > 0 && (
        <React.Fragment>
          <div className={classes.categories}>
            {posts.meta.category.sub_categories.map(x => (
              <Category key={x.id} category={x} linkPrefix={linkPrefix} />
            ))}
          </div>
        </React.Fragment>
      )}

      {posts.data.length > 0 && (
        <React.Fragment>
          <div className={classes.categories}>
            {posts.data.map(x => (
              <Post key={x.id} post={x} linkPrefix={linkPrefix} />
            ))}
          </div>
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[5, 15, 25, 100]}
            component='div'
            count={posts.meta.total}
            rowsPerPage={parseInt(posts.meta.per_page)}
            page={posts.meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

function Category({ category, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/categories/${category.id}`}>
      <Card className={classes.categoryCard}>
        <CardContent>
          <Typography variant='h5' component='h2'>
            {category.name}
          </Typography>
          <Typography variant='body2' component='p'>
            {category.description}
          </Typography>
        </CardContent>
        <div className={classes.categoryPush}></div>
        <CardActions className={classes.categoryActions}>
          <Button size='small'>View</Button>
        </CardActions>
      </Card>
    </Link>
  )
}

function Post({ post, linkPrefix }) {
  const classes = useStyles()

  return (
    <Link to={`/${linkPrefix}/posts/${post.id}`}>
      <Card className={classes.postCard}>
        <CardContent>
          {post.image && (
            <div
              style={{
                height: '160px',
                maxWidth: '100%',
                backgroundImage: `url('${post.image}')`,
                backgroundSize: 'cover',
                backgroundPositionY: 'center',
              }}></div>
          )}
          <Typography variant='h5' component='h2'>
            {post.name}
          </Typography>
        </CardContent>
        <div className={classes.categoryPush}></div>
        <CardActions className={classes.categoryActions}>
          <Button size='small'>View</Button>

          {post.file_count > 0 && (
            <Tooltip
              style={{ marginRight: '0.5rem' }}
              title='Has File Downloads'
              placement='top'>
              <CloudDownloadOutlined />
            </Tooltip>
          )}
          {post.youtube_id && (
            <Tooltip title='Has Video' placement='top'>
              <Videocam />
            </Tooltip>
          )}
        </CardActions>
      </Card>
    </Link>
  )
}

export default PostList
